
import React from 'react';
export default class LoadingScreen extends React.Component {
    state = {
        show: true
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({ show: false })

        }, 1000)
    }
    render() {
        return (this.state.show ? (
            <div className="w-100 min-vh-100 d-flex justify-content-center align-middle" style={{ position: "absolute", backgroundColor: "rgba(13, 11, 11, 0.83)", zIndex: 1231 }}>
                <div style={{ marginTop: "25%" }}>
                    <div className="spinner-grow text-primary"></div>
                    <div className="spinner-grow text-success"></div>
                    <div className="spinner-grow text-info"></div>
                    <div className="spinner-grow text-warning"></div>
                    <div className="spinner-grow text-danger"></div>
                    <div className="spinner-grow text-secondary"></div>
                    <div className="spinner-grow text-dark"></div>
                    <div className="spinner-grow text-light"></div>
                </div>
            </div>
        ) : null)
    }

}


