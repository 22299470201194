export default function details() {
  return {
    bulkget: {
      title: "BulkGet",
      // downloadIos:
      //   "https://install.appcenter.ms/orgs/Nethaat/apps/Breeze-Security-APP/releases/8",
      // downloadAndroid:
      //   "https://install.appcenter.ms/orgs/Nethaat/apps/Breeze-Security-APP/releases/8",
      cards: [
        // {
        //   name: "Discover Parking",
        //   description:
        //     "Discover hundreds of parking spaces around you in the free iPark app, drive-in or pre-book your slot now",
        //   logo: "/img/logos/parking.png"
        // },
        // {
        //   name: "Park your Ride",
        //   description:
        //     "Navigate to the parking location, park your ride, check-in, and check-out with a simple QR scan",
        //   logo: "/img/logos/park.png"
        // },
        // {
        //   name: "Pay online",
        //   description:
        //     "No need to carry cash – use our online payment method, pay through debit, credit cards or net banking.",
        //   logo: "/img/logos/pay-online.png"
        // }
      ],
      description: [
        "A place to come and join hands with our local vendor, get connected to the best online shopping community for the groceries and other products. We bring a change in this world of big titles, a change to help all those who feel left behind in the race of online shopping markets. Let's break the difference and take a leap towards the online market community.",
        "We push ourselves beyond our abilities when faced with tough times. When we foresee uncertainty, we address it only with flexibility.",
        "Feedback is never taken personally; we break it into positive pieces and strive to work on each and every element even more effectively.",
        "People here don’t work ‘for’ Bulkget, they work ‘with’ Bulkget. We treat every problem as our own, take accountability and drive the change.",
        "It’s always ‘us’ over ‘me’. We don’t lose ourselves in pride or confidence during individual successes, but focus on being our simple selves in every which way.",
        "We believe in, stand for and are evangelists of our culture - both, within Bulkget and externally with all our stakeholders",
        "It’s not our abilities that show who we truly are - it’s our choices. We aim to get this right, at least in the majority of the cases.",
        "Bulkget can change the way you move things, how you shop and lets you access your city like never before. We’re an app that connects you to the nearest delivery partner who can make purchases, pick up items from any store or restaurant in the city and bring them to you.",
        "It’s never easy to make purchases or drop off packages when you get busy with work, get stuck in traffic, or you might even end up forgetting about it completely.",

      ],
      info: [
        // {
        //   name: "Admin User Guide",
        //   data: [
        //     {
        //       title: "Link",
        //       description:
        //         "http://pg-app-08nj6r7xmo0oguc0a011fy276dpiwy.scalabl.cloud/"
        //     }
        //   ]
        // },
        // {
        //   name: "Admin User Guide",
        //   data: [
        //     {
        //       title: "Start off as a channel partner",
        //       description:
        //         "Step 1: ENTER YOUR E-MAIL ID.\n Step 2: ENTER YOUR PHONE NUMBER. BREEZE will send you an OTP to verify the phone number and register your credentials with us.\n Step 3: ENTER THE OTP RECEIVED IN THE REGISTERED MOBILE NUMBER.\n You are now registered with BREEZE…… HAPPY BREEZING!!!! "
        //     },
        //     {
        //       title: "Listing your business with us:",
        //       description:
        //         "Listing your business and becoming a channel partner has never been so easier before, you are just a few clicks away from listing your business on BREEZE."
        //     },
        //     {
        //       title: "Adding the business:",
        //       description: `Step 1: Setup your business location - either by entering the location name in the blank space or moving the marker on the map.
        //             \n Step 2: Setup the entry location of your business location – move and place the marker on the map.
        //             \n Step 3: Manually save the address of the business location – remember, this address is visible to the user on the user app. So make sure to enter the proper address. 
        //             \n Step 4: Select the type of parking space available in your premises – 2 Wheeler/4 Wheeler, select both if you have both types of parking spaces available. And define the number of slots available for each type (for e.g. 12 – 2 Wheeler slots / 15 – 4 Wheeler slots).
        //             \n Step 5: Define the pricing of each slot for One Hour – this will be shown to the user as the minimum price for 1 hour. The total amount the user shall pay at the time of check-out is calculated based on the number of hours he/she has used the parking space for.
        //             \n Step 6: Define whether there is a charging facility for either type of vehicle (2 wheeler / 4wheeler) or both in your parking space and the number of total slots with charging facility. Skip this \n step if you don’t have a charging facility at your parking location, although selecting charging availability for either type of vehicle will further ask you to define the pricing. 
        //             Step 7: Define the pricing for the usage of the charging facility on an hourly basis, this amount will be added along with the base parking price set by you on the previous page. 
        //             For e.g. 
        //                 •	Base parking price = Rs 30/Hr, 
        //                 •	vehicle charging price = Rs 25/Hr 
        //              The total amount to be paid = 55/Hr

        //             Note: The values shown above are solely for example purposes only, only you shall possess the authority to set the pricing for your parking slot and charging facility usage.
        //             Step 8: Save a catchy name for your business. 
        //             Reminder! The name you save here will be displayed on the user app as your business name, so choose a nice name wisely.
        //             Congratulations! You successfully listed your business. Now sit back and relax, and let BREEZE do the rest of the job. You can monitor and operate your business any time from the drive-in dashboard, don’t forget to activate your slot in ‘manage your slot’ option.`
        //     },
        //     {
        //       title: "Business dashboard: ",
        //       description: `You shall see all your businesses along with the address listed in a grid view, click on the specific Business/Parking location to explore the range of actions you can take for the business/Parking location.
        //             On clicking any business, you can view and modify every detail of your enlisted business any number of times. Isn’t this the best part of self-onboarding you have all the liberty to change or modify your business as per your wish and will. 
        //             From viewing the ‘Transactions of last seven days’ to modifying the CUG (closed user group) function, you have it all on this page. 
        //             Read further to understand how it works - 
        //             `
        //     },
        //     {
        //       title: "LAST SEVEN DAYS TRANSACTIONS:",
        //       description: `All the transactions – the cash collected by your security guard and the amount paid online from the last seven days is laid out for you in the form of a bar graph because we find understanding a bar graph is very easy.
        //             You can monitor this bar graph to get an idea of how your business is doing, gives you an insight if there are any amendments to be made to increase your revenue generation. 
        //             Compare your business on different days, see when your business does the best. This will help you in modifying the surge prices on peak days.`
        //     },
        //     {
        //       title: "OVERVIEW",
        //       description: `Timing: This shows the time of availability of your parking slot. 
        //             Security guards: This shows the number of security guards you have added on duty for your parking lot. 
        //             Four wheeler slots: The number of four-wheeler slots available at your business location. 
        //             Four wheeler w/charging: The number of four-wheeler slots available with charging facility at your business location.`
        //     },
        //     {
        //       title: " Two-wheeler slots",
        //       description: `The number of two-wheeler slots available at your business location.`
        //     },
        //     {
        //       title: "Two-wheeler w/charging:",
        //       description: `The number of two-wheeler slots available with charging at your business location. `
        //     },
        //     {
        //       title: "Manage security:",
        //       description: `This functionality helps you monitor the security guards on duty; you can add or delete the security guard making him ‘on duty’ or ‘off duty’.
        //             Please note that the security guard can log-in to the security app only if the admin (you) adds his number in ‘Manage security’. `
        //     },
        //     {
        //       title: "Adding security: ",
        //       description: `•	Enter security number
        //             •	Enter security name
        //         Click on the ‘+ Add security’ button to add the security guard on duty. 
        //         Below is the list of security guards you have added on duty.`
        //     },
        //     {
        //       title: "Manage parking lot:",
        //       description: `You can manage your parking slot from here, 
        //             Enable or disable your parking lot according to the availability of the slot. 
        //             Disabling your parking slot will not display your slot on the user app. 
        //             Please note, after successfully onboarding your parking lot you have to enable your parking lot in ‘manage parking lot’
        //             You can delete your parking lot as well. Click on the ‘delete parking your premise’ button to delete your parking slot. `
        //     },
        //     {
        //       title: "Transaction list:",
        //       description: `Having a log of all the transactions of your business is a must, the transaction list here helps you find transactions from any previous day.
        //             Enter the START and END DATE of the list you wish to view and click - APPLY. 
        //              To get a customized list of all the transaction which took place on that specific date. 
        //             You can view the following details for a particular transaction:
        //                 •	Check-in Date 
        //                 •	Check-in Time 
        //                 •	Check – out Date 
        //                 •	Check – out Time
        //                 •	Check-in Security 
        //                 •	Check – out security
        //                 •	Vehicle number 
        //                 •	Amount 
        //                 •	Payment mode `
        //     }
        //   ]
        // },
        // {
        //   name: "How to's",
        //   data: [
        //     {
        //       title: "",
        //       description: ""
        //     },
        //     {
        //       title: "",
        //       description: ""
        //     },
        //     {
        //       title: " ",
        //       description: ""
        //     },
        //     {
        //       title:
        //         " ",
        //       description: ""
        //     },

        //   ]
        // },
        // {
        //   name: "FREQUENTLY ASKED QUESTIONS",
        //   data: [
        //     {
        //       title: "What is iPark?",
        //       description: `iPark is your parking genie! Rent a parking space when you need it, you can book a parking space from the iPark free app.`
        //     },
        //     {
        //       title: "Where can I download the app?",
        //       description: `Download the app from the play store or app store, available in android and iOS.`
        //     },
        //     {
        //       title: "How to verify my mobile number or e-mail?",
        //       description: `We will send you a one time password to your mobile number at the time of registering your number.`
        //     },
        //     {
        //       title: "Should I carry my physical id proof/documents at the time of check-in?",
        //       description: `Showing a physical id proof at the time of check-in is subject to the mandate set by the parking lot admin, at the time of booking you will be informed to carry your id proof.`
        //     },
        //     {
        //       title: "How to book a parking space?",
        //       description: `Log on to the iPark app, Enter the area/building name to discover all the available parking space - Choose a convenient parking space and click the book button. Navigate to the location and park your vehicle with a simple QR code scan.`
        //     },
        //     {
        //       title: "How do I locate a parking lot that I have booked?",
        //       description: `Use the in-app navigation system or reach out to your booking location with the address provided.`
        //     },
        //     {
        //       title: "I'm using a hired vehicle, can I still make a booking?",
        //       description: 'Yes, you can make a booking as long as you scan the QR code at the time of check-in which is present in the mobile app from which the booking is made.'
        //     },
        //     {
        //       title: "How to identify a parking lot with a charging facility?",
        //       description: `Locations with charging facility have special icons, look out for the icon at the time booking a parking space.`
        //     },
        //     {
        //       title: "Can I cancel a booking if I change my mind?",
        //       description: `You can cancel a booking, from your upcoming bookings page in the iPark app.`
        //     },
        //     {
        //       title: "I’m a property owner, how can I partner with iPark?",
        //       description: `Becoming a business partner for property owners is very easy, just sign-up with your E-mail id and mobile number to self onboard your business in few minutes.`
        //     }
        //   ]
        // },
        // {
        //   name: "FOR PARKING OWNERS AND MANAGERS",
        //   data: [
        //     {
        //       title: "Link",
        //       description: "http://pg-app-08nj6r7xmo0oguc0a011fy276dpiwy.scalabl.cloud/"

        //     },


        //   ]
        // },
        {
          name: "Terms & Conditions",
          type: "tnc",
          data: [
            {

              title: "USER TERMS AND CONDITIONS",
              description: `<p>This forms a legally binding agreement between Members (&ldquo;customer(s)/you/your/ yourself&rdquo;) and Nethaat Interactive Solutions Private Limited and/or its subsidiaries and affiliates (&ldquo;BulkGet/ we/ us/our&rdquo;). These terms shall govern the use of the website, mobile application, help desk (collectively referred to as &ldquo;BulkGet Platform&rdquo;) which enables customers to connect with us in relation to the products offered through the BulkGet Platform ("products").&nbsp; These terms of use ("Terms of Use") mandate the terms on which users ("You" or "Your" or "Yourself" or "Users") interested in browsing or availing BulkGet Services (defined below), and accessing the platform www.BulkGet.in and the mobile application owned and operated by BulkGet Digital Private Limited ("BulkGet") collectively referred to as, the "Platform" connects with the Merchants registered on the Platform ("Shops/Restaurants/Stores/Stand-alone shops "), together hereinafter referred to as "Vendors" and with delivery partners ( referred to as "Delivery Captains") to avail the BulkGet Services.</p>
              <p>Please read the Terms of Use carefully before using the Platform or registering on the Platform or accessing any material or information through the Platform.</p>
              <p>By clicking on the "I Accept" button, you accept this Terms of Use and agree to be legally bound by the same.</p>
              <p>This document is an electronic record and will be governed under the Information Technology Act, 2000 and Rules thereunder as applicable and the provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
              <p>Use of and access to the Platform is offered to You upon the condition of acceptance of all the terms, conditions and notices contained in this Terms of Use and Privacy Policy, along with any amendments made by BulkGet at its sole discretion and posted on the Platform from time to time.</p>
              <p>For the purposes of these Terms of Use, the term 'BulkGet' or 'Us' or 'We' refers to BulkGet Digital Private Limited. The term 'You' refers to the user or visitor of the Website and/or App. When You use our services, you will be subject to the terms, guidelines and policies applicable to such service and as set forth in these Terms of Use. As long as you comply with these Terms of Use, we grant You a personal, non-exclusive, non-transferable, limited privilege to enter and use our Platforms and services.</p>
              <p><strong>Registration:</strong></p>
              <p>You shall be permitted to access the Platform, avail the BulkGet Services and connect with Vendors and Delivery captains on the Platform only upon creating an Account (as defined below) and obtaining a registration on the Platform. Your ability to continue using the Platform, BulkGet Services is subject to Your continued registration on the Platform. You will be required to enter Your personal information including your name and a valid phone number while registering on the Platform. As a part of the registration, you may be required to undertake a verification process to verify Your personal information and setting up the Account.</p>
              <p>BulkGet shall have the right to display the information and ratings provided by You on the Platform. You agree and accept that as on the date of Your registration on the Platform, the information provided by You is complete, accurate and up-to-date. You acknowledge and accept that BulkGet has not independently verified the information provided by You. BulkGet shall in no way be responsible or liable for the accuracy or completeness of any information provided by You. If You provide any information that is untrue, inaccurate, not current or incomplete, or BulkGet has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, BulkGet reserves the right to suspend or terminate Your Account (defined below) and refuse any and all current or future use of the Platform (or any portion thereof) at any time.</p>
              <p><strong>User Account, Password, and Security</strong></p>
              <p>In order to use the Platform and avail the BulkGet Services, You will have to register on the Platform and create an account with a unique user identity and password ("Account") if you are browsing through a Computer. Also, If You are using the Platform on a compatible mobile or tablet, You will have to register on the platform and then proceed with Successfully placing the order.</p>
              <p>You will be responsible for maintaining the confidentiality of the Account information, and are fully responsible for all activities that occur under Your Account. You agree to (i) immediately notify BulkGet of any unauthorized use of Your Account information or any other breach of security, and (ii) [ensure that You exit from Your Account at the end of each session.] BulkGet cannot and will not be liable for any loss or damage arising from Your failure to comply with this provision. You may be held liable for losses incurred by BulkGet or any other User of or visitor to the Platform due to authorized or unauthorized use of Your Account as a result of Your failure in keeping Your Account information secure and confidential. Use of another User&rsquo;s Account information for using the Platform is expressly prohibited.</p>
              <p><strong><u>BulkGet Services to the user:</u></strong></p>
              <p>&nbsp;<strong>The Platform provides You with the following services ("BulkGet Services"):</strong></p>
              <ul>
              <li>It allows You to connect with Vendors and Delivery Captains;</li>
              <li>It allows You to view the items/services ("Items") listed on the Platform by the Vendors;</li>
              <li>It allows You to purchase Item(s) from the Vendors listed on the Platform and allows You to get the Items delivered to You through Delivery captains ("Purchase Services");</li>
              <li>It allows You to purchase Items from Vendors and get the same delivered to You at a scheduled time and date provided the Vendors by the delivery captian. ("Scheduled Delivery");</li>
              <li>It allows you get faster delivery of your products with some extra charges from 1 (one) location to the other through the Delivery captain ("Just in Time");</li>
              <li>It allows You to purchase and then pick up the item from the Vendor&rsquo;s outlet after some processing time set by the Vendor ("Take Away");</li>
              <li>It allows you to avail offers given by the Vendors for selected societies.</li>
              <li>It allows You to give ratings about Vendors;</li>
              <li>It facilitates improvement in the quality of the services provided by BulkGet on the Platform based on User ratings provided on the Platform.</li>
              </ul>
              <p>Once the Delivery Services/ Purchase Services/Just in Time/Scheduled Delivery / Takeaway have been completed or delivered, as the case may be, you shall promptly notify the same on the Platform.</p>
              <p>BulkGet may, at its absolute sole discretion, add, modify, upgrade, extend, withdraw or alienate any of the BulkGet Services listed above from time to time. BulkGet does not provide any guarantee to You that the BulkGet Services will be made available to You at all times.</p>
              <p>You hereby agree and acknowledge that BulkGet is only a facilitator between You, the Vendors and Delivery Captain (as the case maybe) and BulkGet only provides You with access to the Platform to connect with Vendors and Delivery captains for You to initiate transactions on the Platform. You hereby agree and acknowledge that BulkGet will not be a party to any of the transactions that are initiated by You through the Platform and BulkGet shall not be liable in any manner or incur any liability with respect to the services performed by the Vendors or the Delivery captains, as the case may be. Further, You hereby agree and acknowledge that BulkGet shall not be liable for the conduct, acts and omissions of the Vendors (including their employees and consultants) and Delivery captains in the course of providing their services to You, or for any loss or damage to the Item or otherwise caused to You as a consequence of or in relation to the services being provided to You by the Vendors or the Delivery captain, as the case may be.</p>
              <p>BulkGet does not check or verify the packages that are being taken away at the Vendor&rsquo;s Outlet on behalf of You or the Items that are being delivered to You by the Delivery captain, and therefore BulkGet shall have no liability with respect to the same. However, if it comes to the knowledge of BulkGet that You have packaged any illegal or dangerous substance or availed the Taken Away or Delivered by the Delivery Captain using the Platform to deliver any illegal or dangerous substance, BulkGet shall have the right to report You to the government authorities and take other appropriate legal actions against You.</p>
              <p>You hereby acknowledge that BulkGet shall not be liable for any damages of any kind arising from Your use of the BulkGet Services, including, but not limited to direct, indirect, incidental, punitive, and consequential damages.</p>
              <p>You hereby agree that BulkGet shall not be liable for any conduct or misbehavior or actions of Delivery captain with respect to any transactions initiated on the Platform. Further, you agree that BulkGet has no control over the Items provided to You by the Vendors and therefore, BulkGet shall not incur any liability with respect to such Items. However, keeping in mind the interests of the Users, we have informed our Vendors to ensure that Items are packaged properly to avoid any form of spillage or damage to the Item or any issues related to packaging.</p>
              <p>You hereby agree that scheduling and rescheduling an Order on the Platform depends upon the availability of Delivery captains around Your area and the decision of the Vendor at the time of such scheduling and re-scheduling an Order. Should You choose to reschedule a transaction on the Platform at a later point of time, you shall cancel the current Order on the Platform (if initiated) and initiate a new Order on the Platform, as per Your convenient time.</p>
              <p>&nbsp;</p>
              <p>If an Order initiated by You on the Platform cannot be completed, BulkGet shall notify You on the Platform.</p>
              <p>You agree to provide as much information as possible on the Platform with respect to the Items/services You wish to purchase/avail, using the Platform.</p>
              <p>BulkGet shall use Your location-based information, captured by BulkGet through global positioning system when You are using our website to request a BulkGet Service on its m-app. Such location-based information shall be used by BulkGet only to facilitate and improve the BulkGet Services being offered to You.</p>
              <p>We can&rsquo;t fulfil any tasks which are immoral or unlawful in nature. BulkGet reserves the right to refuse to perform any tasks on the grounds of such tasks being immoral/unethical/unlawful/banned either by BulkGet&rsquo;s internal policies or as per the independent discretion of BulkGet. BulkGet may also refuse to perform any task on the grounds that such task is prohibited under any contract to which we are party.</p>
              <p>&nbsp;You understand and acknowledge that BulkGet by itself does not sell or provide any such Items. BulkGet is not responsible for the quality, Vendor ability or fitness of such Items. Accordingly, in the event of any grievances arising from the transaction initiated by You on the Platform pertaining to purchase or sale of any product from any Vendor, you may contact BulkGet support for routing. your grievances to the Vendor through the Platform.</p>
              <p><strong>User Information</strong></p>
              <p>&nbsp;You are solely responsible for and in control of the information You provide to us. Compilation of User Accounts and User Account bearing contact number and e-mail addresses are owned by BulkGet. Further, you understand and agree that certain information will be case sensitive and must be handled with a prudent care.</p>
              <p>&nbsp;</p>
              <p>In the case where the Platform is unable to establish unique identity of the User against a valid mobile number or e-mail address, the Account shall be indefinitely suspended. BulkGet reserves the full discretion to suspend a User's Account in the above event and does not have the liability to share any Account information whatsoever.</p>
              <p><strong>Payment Terms</strong></p>
              <p>Purchase Services: While initiating a request for a Purchase Service, you shall pay for the price of the Items You require the Delivery captains to deliver to You from the Vendor. The transaction for the Purchase Service will be initiated on the Platform once You have completed the payment for the same on the Platform. In certain exceptional circumstances, if the purchase price of the Item is not available on the Platform, you shall be required to pay the purchase price of the Item, through the Platform, as may be communicated to You by the Delivery captain, prior to the Delivery captain undertaking Purchase Service.</p>
              <p>Scheduled Delivery Services: While availing Delivery Service, you shall pay the purchase price of the Item through the Platform, as may be communicated to You by the Delivery captain on behalf of the Vendor. Only upon processing such agreed amount via Platform, shall the Delivery captain purchase the Item on Your behalf.</p>
              <p>Takeaway Service: While initiating the Takeaway service you have to visit the Vendor&rsquo;s outlet to receive and pick-up your placed order, The processing time of order confirmation or your ordered products to be packed and ready to pick-up would vary from Vendor to Vendor.</p>
              <p>Just in Time (JIT): While initiating a request for a Just in Time, you shall pay the service fees (JIT charge set by the Vendor) for availing the Just in Time, as may be displayed to You on the Platform. Only upon making such payment will the Delivery captain initiate the Just in Time Service.</p>
              <p>Delivery Charges: With respect to Delivery Services and Purchase Services, You will be charged a separate service fees ("Delivery Charge") for the delivery of your products by the Delivery Captain. The Delivery Charges shall may vary for Different Vendors.</p>
              <p>You agree that BulkGet may use certain third-party vendors and service providers, including payment gateways, to process the payments made by You on the Platform.</p>
              <p>BulkGet and its affiliates reserve the right to use, share and display such orders and offers by the Vendor in any manner in connection with the business of BulkGet and its affiliates without attribution to or approval of Users and You hereby consent to the same.</p>
              <p>Location: You acknowledge and agree that Your geo-location information is required for You to avail the BulkGet Services and initiate transactions on the Platform while browsing our website. You acknowledge and hereby consent to the monitoring and tracking of Your geo-location information. In addition, BulkGet may share Your geo-location information with Delivery captains and Vendors (as the case maybe).</p>
              <p><strong>&nbsp;</strong></p>
              <p><strong>Cancellation and Refund</strong></p>
              <p>BulkGet shall confirm and initiate the execution of the Order initiated by You upon receiving confirmation from You for the same. If You wish to cancel an Order on the Platform, You shall select the cancel option on the Platform. It is to be noted that You may have to pay a cancellation fee for transactions initiated on the Platform for which work has already been commenced by the Delivery captain or the Vendor, as the case may be. With respect to work commenced by Vendors the cancellation fee will be charged to You which will be in accordance with the cancellation and refund policies of such Vendors.</p>
              <p>Vendors may cancel the transaction initiated by You on the Platform, if:</p>
              <p>The designated address to avail the BulkGet Services provided by You is outside the service zone of BulkGet.</p>
              <p>Failure to get your response via phone or any other communication channel at the time of confirmation of the order booking.</p>
              <p>The transaction involves supply/delivery/purchase of any material good that is illegal, offensive or violative of the Terms of Use.</p>
              <p>&nbsp;You hereby acknowledge that if You have any complaint with respect to the BulkGet Services, you will first inform BulkGet in writing within 24 (twenty-four) hours of using such BulkGet Services.</p>
              <p>[If the transaction involves the purchase of medicines for which a medical prescription prescribed by a medical practitioner is required and for which You have not provided such medical prescription or provided an invalid medical prescription.]</p>
              <p>Information, instructions and authorizations provided by You is not complete or sufficient to execute the transaction initiated by You on the Platform.</p>
              <p>If in case of, the Vendor outlet is closed.</p>
              <p>If a Delivery captain is not available to perform the services, as may be requested.</p>
              <p>If any Item for which You have initiated the transaction is not in stock with the Vendor.</p>
              <p>If the transaction cannot be completed for reasons not in control of BulkGet.</p>
              <p>You shall only be able to claim refunds for transactions initiated by You only if You have already pre-paid the fees with respect to such transaction. Subject to relevant Vendor&rsquo;s refund policy and in accordance therein, you shall be eligible to get the refund in the following circumstances:</p>
              <p>Your package has been tampered or damaged at the time of delivery, as determined by BulkGet basis the parameters established by BulkGet in its sole discretion.</p>
              <p>If the wrong Item has been delivered to You, which does not match with the Item for which You had initiated a transaction on the Platform.</p>
              <p>BulkGet has cancelled the order because of any reason mentioned under Para 6 (b) above.</p>
              <p>All decisions with respect to refunds will be at the sole discretion of BulkGet and in accordance with BulkGet&rsquo;s internal refund policy (Refund Metrix) and the same shall be final and binding. All refunds initiated by BulkGet shall be refunded to the financial source account from which, You have initiated the transaction on the Platform.</p>
              <p>As a part of the BulkGet Services, BulkGet also gives You an option to avail the Just in Time/Scheduled Delivery being provided by the Delivery captains.</p>
              <p>You can initiate an Order on the Platform by which You may (through the help of a Delivery captain) send packages at a particular location. The Just in Time/Scheduled Delivery are provided to You directly by the Delivery captain and BulkGet merely acts as a technology platform to facilitate transactions initiated on the Platform and BulkGet does not assume any responsibility or liability for any form of deficiency of services on part of the Delivery captain.</p>
              <p>Upon initiation of a request for Just in Time/Scheduled Delivery on the Platform, depending upon the availability of Delivery captain around Your area, BulkGet will assign a Delivery captain to You. The Delivery captain shall pick up the Item from a location designated by You on the Platform and drop off the Items at a particular location designated by You. While performing the Just in Time/Scheduled Delivery, the Delivery captain shall act as an agent of You and shall act in accordance with Your instructions.</p>
              <p>You agree that before requesting a Takeaway/Just in Time/Scheduled Delivery, you are well aware of the contents of the package requested by You through a registered Vendor, and that such contents are legal and within limits of transportation under any applicable laws. Such contents shall not be restricted and/or banned and/or dangerous and/or prohibited for carriage (such items include, but are not limited to, radio-active, incendiary, corrosive or flammable substances, hazardous chemicals, explosives, firearms or parts thereof and ammunition, firecrackers, cyanides, precipitates, gold and silver ore, bullion, precious metals and stones, jewelry, semi-precious stones including commercial carbons or industrial diamonds, currency (paper or coin) of any nationality, securities (including stocks and bonds, share certificates and blank signed share transfer forms), coupons, stamps, negotiable instruments in bearer form, cashier's cheques, traveler&rsquo;s cheques, money orders, passports, credit/debit/ATM cards, antiques, works of art, lottery tickets and gambling devices, livestock, fish, insects, animals, plants and plant material, human corpses, organs or body parts, blood, urine and other liquid diagnostic specimens, hazardous or bio-medical waste, wet ice, pornographic materials, contraband, bottled alcoholic beverages or any intoxicant or narcotics and psychotropic substances or any other prohibited material or material for the transportation of which specific authorization/license is required under applicable laws).</p>
              <p>You hereby agree and acknowledge that the Offers are being extended by BulkGet at its sole independent discretion and nothing shall entitle You to any of the Offers. You shall read the terms and conditions of the Offers carefully before availing them.</p>
              <p><strong>Intellectual Property Rights</strong></p>
              <p>&nbsp;</p>
              <p>We alone (and our licensors, where applicable) shall own all right, title and interest, including all related intellectual property rights, in and to</p>
              <ol style="list-style-type: lower-roman;">
              <li>the Site, Application, product and any suggestions, ideas, enhancement requests, feedback, recommendations or any other offering;</li>
              <li>text, graphics, customer interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, artwork and computer code; or</li>
              <li>other information provided by You or any other party relating to the Site, Application or the product. Third party trademarks may appear on this Site/ Application and all rights therein are reserved to the registered owners of those trademarks. For use of any third party's intellectual property,</li>
              </ol>
              <p>You need permission from the owner of those intellectual properties These Terms do not constitute a sale and do not convey to You any rights of ownership in or related to the Site, the Application or the product, or any intellectual property rights owned by us. The member stands responsible for any violations of any laws and for any infringements of intellectual property rights caused by use of the products or the Site/ Application. You may use information on the Site purposely made available by us for downloading from the Site, provided that You:</p>
              <ol style="list-style-type: lower-roman;">
              <li>do not remove any proprietary notice language in all copies of such documents and make no modifications to the information;</li>
              <li>use such information only for Your personal, non-commercial informational purpose and do not copy or post such information on any networked computer or broadcast it in any media; or</li>
              <li>do not make any additional representations or warranties relating to such information. Indemnification Without prejudice to and in addition to any other remedies, reliefs or legal resources available to us herein or any applicable laws or otherwise, the Member agrees to indemnify, defend and hold us harmless including but not limited to its affiliate, agents and employees from and against any and all losses, liabilities, claims, damages, demands, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by us that arise out of or related to the customer&rsquo;s use or misuse of the Site, any violation by the customer of these Terms, or any breach of representations, warranties and covenants made by the customer herein.</li>
              </ol>
              <p><strong>Warranty</strong></p>
              <p>&nbsp;</p>
              <p>The products provided by us will be on "as is" basis without warranty of any kind, express, implied, statutory or otherwise, including the implied warranties of title, non-infringement, merchantability or fitness for a particular purpose. Without limiting the foregoing, we make no warranty that</p>
              <ol style="list-style-type: lower-roman;">
              <li>Platform or the products will meet the customer&rsquo;s requirements or the customer&rsquo;s use of the Platform or will be uninterrupted, timely, secure or error-free;</li>
              <li>the results that may be obtained from the use of the Platform, or products will be effective, accurate or reliable;</li>
              <li>the quality of the Platform, or products will meet your expectations; or that</li>
              <li>any errors or defects in the Platform or products will be corrected. No advice or information, whether oral or written, obtained by the Members from us or through or from use of the Platform shall create any warranty not expressly stated in the terms of use. We shall have no liability to the customer for any interruption or delay, to access the Platform irrespective of the cause.</li>
              </ol>
              <p>It is hereby further clarified that we and the vendors are separate and independent entities and we do not work as representative or agent of the vendors. By making a order on the Platform, the Member enters into commercial/ contractual terms as offered by and agreed to between the vendor and the Member alone.</p>
              <p>We are merely an electronic platform to facilitate provision of products pertaining to home delivery of products between the Members and the vendors. It is pertinent to note that the Member should be aware about the possible risks of ordering or making use or relying on the information relating to the products. It is the Member's responsibility to check the details of the Listing on the Platform at its sole discretion.</p>
              <p>&nbsp;</p>
              <p><strong>Limitation of Liability</strong></p>
              <p>&nbsp;</p>
              <p>We do not stand liable to any damages of any kind whatsoever including but not limited to direct, indirect, incidental, punitive, exemplary and consequential damages, damages for loss of use, data or profits, or other intangible losses, which may arise or are arising from the use of Platform or any of the information, software, products and related graphics contained within the Platform or any of the products offered, regardless of whether such damages are based on contract, tort, negligence, strict liability or otherwise, and even if we have been advised of the possibility of damages.</p>
              <p>&nbsp;</p>
              <p>Governing Law and Jurisdiction This Agreement, and all transactions entered into on or through Platform shall be interpreted, construed and governed by the laws of India which shall be applicable to this Agreement without regard to principles of conflict of laws. Any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation or validity thereof or the use of the Site, products or the Application (collectively, &ldquo;Disputes&rdquo;) will be subject to the exclusive jurisdiction of courts at Mumbai India. Any Dispute arising from or in connection with this Agreement shall be referred to arbitration under the Arbitration and Conciliation Act, 1996 to be adjudicated by a sole arbitrator appointed by us. The arbitration shall be held in Mumbai and the language in which the arbitration proceedings shall be conducted will be English. The arbitral award rendered shall be final and binding upon both the parties. The Members acknowledge that pursuant to these Terms, the Members will have access to our confidential information which has been provided by us. The Members further undertake to keep all confidential data and shall not sell or otherwise make that information available to any third party.</p>
              <p>&nbsp;</p>
              <p>Except as otherwise agreed, the data of customers will be our exclusive property, and the vendors will not use the same for their own purpose or distribute such data in any form or means except for the terms agreed upon and maintain its confidentiality at all times. The Confidential information would include but not be limited to customer details, market information and documents related thereto, the contents of the Site, Application or any other information which is treated as confidential by us, and any other information, whether orally or in writing, received or to be received by the Members which is agreed to be treated as confidential, whether expressly or by implication. Notice We may give notice by means of a general notice on the product or Application, or by electronic mail to the Members email address or a message on the Members registered mobile number. Force Majeure Any delay in or failure to perform any obligations by any Member under the Terms shall not constitute default hereunder if and to the extent caused by force majeure, which is defined to be occurrences beyond the reasonable control of such Member committing default, including and limited to acts of the government authorities, acts of god, fire, flood, explosion, riots, war, rebellion, insurrection etc.</p>
              <p>&nbsp;</p>
              <p>No Waiver Any failure, delay or forbearance on our part in:</p>
              <ol style="list-style-type: lower-roman;">
              <li>exercising any right, power or privilege under this Agreement; or</li>
              <li>enforcing terms of this Agreement, shall not operate as a waiver thereof, nor shall any single or partial exercise by us of any right, power or privilege preclude any other future exercise or enforcement thereof.</li>
              </ol>
              <p>Conflict In the event of any contradiction or inconsistency between these Terms and any other agreement executed between the Members and NSIPL, these Terms shall prevail unless the exception has been expressly agreed to in writing by making reference to the relevant clause sought to be modified under these Terms.</p>
              <p>&nbsp;</p>
              <p><strong>Severability</strong></p>
              <p>&nbsp;</p>
              <p>The Members hereto agree that each of the provisions contained in this Agreement shall be severable, and the unenforceability of one or more provisions of this Agreement shall not affect the enforceability of any other provision(s) of this Agreement.</p>
              `
            }
          ]
        },
        {
          name: "Refund Policy",
          type: "pripol",
          data: [{
            title: "Refund and Cancellation",
            description: `<p>BulkGet shall confirm and initiate the execution of the Order initiated by You upon receiving confirmation from You for the same. If You wish to cancel an Order on the Platform, You shall select the cancel option on the Platform. It is to be noted that You may have to pay a cancellation fee for transactions initiated on the Platform for which work has already been commenced by the Delivery captain or the Vendor, as the case may be. With respect to work commenced by Vendors the cancellation fee will be charged to You which will be in accordance with the cancellation and refund policies of such Vendors.</p>
            <p>Vendors may cancel the transaction initiated by You on the Platform, if:</p>
            <p>The designated address to avail the BulkGet Services provided by You is outside the service zone of BulkGet.</p>
            <p>Failure to get your response via phone or any other communication channel at the time of confirmation of the order booking.</p>
            <p>The transaction involves supply/delivery/purchase of any material good that is illegal, offensive or violative of the Terms of Use.</p>
            <p>&nbsp;You hereby acknowledge that if You have any complaint with respect to the BulkGet Services, you will first inform BulkGet in writing within 24 (twenty-four) hours of using such BulkGet Services.</p>
            <p>[If the transaction involves the purchase of medicines for which a medical prescription prescribed by a medical practitioner is required and for which You have not provided such medical prescription or provided an invalid medical prescription.]</p>
            <p>Information, instructions and authorizations provided by You is not complete or sufficient to execute the transaction initiated by You on the Platform.</p>
            <p>If in case of, the Vendor outlet is closed.</p>
            <p>If a Delivery captain is not available to perform the services, as may be requested.</p>
            <p>If any Item for which You have initiated the transaction is not in stock with the Vendor.</p>
            <p>If the transaction cannot be completed for reasons not in control of BulkGet.</p>
            <p>You shall only be able to claim refunds for transactions initiated by You only if You have already pre-paid the fees with respect to such transaction. Subject to relevant Vendor&rsquo;s refund policy and in accordance therein, you shall be eligible to get the refund in the following circumstances:</p>
            <p>Your package has been tampered or damaged at the time of delivery, as determined by BulkGet basis the parameters established by BulkGet in its sole discretion.</p>
            <p>If the wrong Item has been delivered to You, which does not match with the Item for which You had initiated a transaction on the Platform.</p>
            <p>BulkGet has cancelled the order because of any reason mentioned under Para 6 (b) above.</p>
            <p>All decisions with respect to refunds will be at the sole discretion of BulkGet and in accordance with BulkGet&rsquo;s internal refund policy (Refund Metrix) and the same shall be final and binding. All refunds initiated by BulkGet shall be refunded to the financial source account from which, You have initiated the transaction on the Platform.</p>`
          }]
        },
        {
          name: "Privacy Policy",
          type: "pripol",
          data: [{
            title: "Your website may use the Privacy Policy given below:",
            description: `
            <p>The terms "We" / "Us" / "Our"/&rdquo;Company/Bulkget platform&rdquo; individually and collectively refer to Nethaat interactive solutions private limited and the terms "You" /"Your" / "Yourself" refer to the users.&nbsp;</p>
            <p>This Privacy Policy is an electronic record in the form of an electronic contract formed under the information Technology Act, 2000 and the rules made thereunder and the amended provisions pertaining to electronic documents / records in various statutes as amended by the information Technology Act, 2000. This Privacy Policy does not require any physical, electronic or digital signature.</p>
            <p>This Privacy Policy is a legally binding document between you and Nethaat interactive solutions private limited. The terms of this Privacy Policy will be effective upon your acceptance of the same (directly or indirectly in electronic form, by clicking on the I accept tab or by use of the platform or by other means) and will govern the relationship between you and Nethaat interactive solutions private limited for your use of the website &ldquo;<strong>Bulkget platform</strong>&rdquo; (defined below).</p>
            <p>This document is published and shall be construed in accordance with the provisions of the Information Technology (reasonable security practices and procedures and sensitive personal data of information) rules, 2011 under Information Technology Act, 2000; that require publishing of the Privacy Policy for collection, use, storage and transfer of sensitive personal data or information.</p>
            <p>Please read this Privacy Policy carefully by using the Bulkget platform, you indicate that you understand, agree and consent to this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use this platform.&nbsp;</p>
            <p>By providing us your Information or by making use of the facilities provided by the Bulkget platform, You hereby consent to the collection, storage, processing and transfer of any or all of Your Personal Information and Non-Personal Information by us as specified under this Privacy Policy. You further agree that such collection, use, storage and transfer of Your Information shall not cause any loss or wrongful gain to you or any other person.</p>
            `
          },
          {
            title: "USER INFORMATION",
            description: `
            <p>To avail certain services on our Websites, users are required to provide certain information for the registration process namely: - a) your name, b) email address, c) credit card or debit card details d) mobile number. The Information as supplied by the users enables us to improve our sites and provide you the most user-friendly experience.</p>
            <p>All required information is service dependent and we may use the above said user information to, maintain, protect, and improve its services (including advertising services) and for developing new services</p>
            <p>Such information will not be considered as sensitive if it is freely available and accessible in the public domain or is furnished under the Right to Information Act, 2005 or any other law for the time being in force.</p>
            `
          },
          {
            title: "LINKS TO THE OTHER SITES",
            description: `<p>Our policy discloses the privacy practices for our own platform only. Our site provides links to other websites also that are beyond our control. We shall in no way be responsible in any way for your use of such sites.</p>`
          },
          {
            title: "INFORMATION SECURITY",
            description: `<p>We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data. These include internal reviews of our data collection, storage and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data.</p>
            <p>All information gathered on our platform is securely stored within our controlled database. The database is stored on servers secured behind a firewall; access to the servers is password-protected and is strictly limited. However, as effective as our security measures are, no security system is impenetrable. We cannot guarantee the security of our database, nor can we guarantee that information you supply will not be intercepted while being transmitted to us over the Internet.</p>
            <p>However the internet is an ever evolving medium. We may change our Privacy Policy from time to time to incorporate necessary future changes. Of course, our use of any information we gather will always be consistent with the policy under which the information was collected, regardless of what the new policy may be.</p>
            `
          },
          {
            title: "Grievance Redressal",
            description: `<p>Redressal Mechanism: Any complaints, abuse or concerns with regards to content and or comment or breach of these terms shall be immediately informed to the designated Grievance Officer as mentioned below via in writing or through email signed with the electronic signature to&nbsp;Yasir ali ("Grievance Officer").</p><br/>
            <p>Mr. Yasir Ali <strong>(Grievance Officer)</strong><br /><strong>www.nethaat.in</strong><br />Nethaat Interactive solutions private limited </p>
            <p>Email: sarwesh@nethaat.in<br />Ph: +91 96117 03068</p>
            `
          }
          ]
        },
        {
          name: "Disclaimer",
          type: "disc",
          data: [{
            title: "Interpretation and Definitions",
            description: `<h5><strong>Interpretation</strong></h5>
            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.</p>
            <p>The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
            <h5><strong>Definitions</strong></h5>
            <p><strong>For the purposes of this Disclaimer:</strong></p>
            <ul style="list-style-type: circle;">
              <li><strong>Company</strong> (referred to as either "the Company", "We", "Us", &rdquo;Bulkget&rdquo;, "Our" or &ldquo;platform&rdquo; in this Policy) refers to NetHaat interactive solutions private limited.</li>
              <li><strong>You</strong> means the individual accessing the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
              <li><strong>Application</strong> means the software program provided by the Company downloaded by You on any electronic device named Bulkget.</li>
              <li><strong>Service</strong> refers to the Website or the Application or both.</li>
            </ul>`
          },
          {
            title: "Disclaimer",
            description: `<p>The information contained on the Service is for general information purposes only.</p>
            <p>The Company assumes no responsibility for errors or omissions in the contents of the Service.</p>
            <p>In no event shall the Company be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. The Company reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice. The Company does not warrant that the Service is free of viruses or other harmful components.</p>`
          },
          {
            title: "External Links Disclaimer",
            description: `<p>The Service may contain links to external websites that are not provided or maintained by or in any way affiliated with the Company.</p>
            <p>Please note that the Company does not guarantee the accuracy, relevance, timeliness, or completeness of any information on these external websites.</p>`
          },
          {
            title: "Errors and Omissions Disclaimer",
            description: `<p>The information given by the Service is for general guidance on matters of interest only. Even if the Company takes every precaution to insure that the content of the Service is both current and accurate, errors can occur. Plus, given the changing nature of laws, rules and regulations, there may be delays, omissions or inaccuracies in the information contained on the Service.</p>
            <p>The Company is not responsible for any errors or omissions, or for the results obtained from the use of this information.</p>`
          },
          {
            title: "Views Expressed Disclaimer",
            description: `<p>The Service may contain views and opinions which are those of the authors and do not necessarily reflect the official policy or position of any other author, agency, organization, employer or company, including the Company.</p>
            <p>Comments published by users are their sole responsibility and the users will take full responsibility, liability and blame for any libel or litigation that results from something written in or as a direct result of something written in a comment. The Company is not liable for any comment published by users and reserve the right to delete any comment for any reason whatsoever.</p>`
          },
          {
            title: "No Responsibility Disclaimer",
            description: `<p>The information on the Service is provided with the understanding that the Company is not herein engaged in rendering legal, accounting, tax, or other professional advice and services. As such, it should not be used as a substitute for consultation with professional accounting, tax, legal or other competent advisers.</p>
            <p>In no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever arising out of or in connection with your access or use or inability to access or use the Service.</p>`
          },
          {
            title: `"Use at Your Own Risk" Disclaimer`,
            description: `<p>All information in the Service is provided "as is", with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability and fitness for a particular purpose.</p>
            <p>The Company will not be liable to You or anyone else for any decision made or action taken in reliance on the information given by the Service or for any consequential, special or similar damages, even if advised of the possibility of such damages.</p>
            <h5><strong>Contact Us</strong></h5>
            <p>If you have any questions about this Disclaimer, You can contact Us:</p>
            <ul style="list-style-type: circle;">
            <li>By email: <strong>sarwesh@nethaat.in</strong></li>
            <li>By phone number: <strong>+91 99611 703068</strong></li>
            </ul>`
          }
          ]
        }
      ]
    },
    ipark: {
      title: "iPark",
      downloadIos:
        "https://install.appcenter.ms/orgs/Nethaat/apps/Breeze-Security-APP/releases/8",
      downloadAndroid:
        "https://install.appcenter.ms/orgs/Nethaat/apps/Breeze-Security-APP/releases/8",
      cards: [
        {
          name: "Discover Parking",
          description:
            "Discover hundreds of parking spaces around you in the free iPark app, drive-in or pre-book your slot now",
          logo: "/img/logos/parking.png"
        },
        {
          name: "Park your Ride",
          description:
            "Navigate to the parking location, park your ride, check-in, and check-out with a simple QR scan",
          logo: "/img/logos/park.png"
        },
        {
          name: "Pay online",
          description:
            "No need to carry cash – use our online payment method, pay through debit, credit cards or net banking.",
          logo: "/img/logos/pay-online.png"
        }
      ],
      description: [
        "We all have experienced the pain of finding the right parking spot for our vehicles and making sure it is safe there is another plight. iPark helps you to rent a parking space for your two or four-wheeler near you or pre-book a parking space in or around your pre-determined destination.",
        "Find a convenient parking space in the iPark free app, choose from hundreds of parking spaces in your proximity.What’s more? You can charge your EV in the parking lot, look out for charging facility. Isn’t it so cool? Charging your EV while it is parked.",
        "Reach out to the parking location with the help of navigation, check-in with a simple and smooth QR scan – park your ride leaving the parking stress outside since your ride is now parked safely – Pay online via our online payment methods we don’t like carrying cash either, towards a digital country! aye? Scan the QR once more to check-out, leave the location with a smile.",
        "Download the app now to view all the parking spaces near you, drive-in whenever you want or pre-book your parking space."
      ],
      info: [
        // {
        //   name: "Admin User Guide",
        //   data: [
        //     {
        //       title: "Link",
        //       description:
        //         "http://pg-app-08nj6r7xmo0oguc0a011fy276dpiwy.scalabl.cloud/"
        //     }
        //   ]
        // },
        // {
        //   name: "Admin User Guide",
        //   data: [
        //     {
        //       title: "Start off as a channel partner",
        //       description:
        //         "Step 1: ENTER YOUR E-MAIL ID.\n Step 2: ENTER YOUR PHONE NUMBER. BREEZE will send you an OTP to verify the phone number and register your credentials with us.\n Step 3: ENTER THE OTP RECEIVED IN THE REGISTERED MOBILE NUMBER.\n You are now registered with BREEZE…… HAPPY BREEZING!!!! "
        //     },
        //     {
        //       title: "Listing your business with us:",
        //       description:
        //         "Listing your business and becoming a channel partner has never been so easier before, you are just a few clicks away from listing your business on BREEZE."
        //     },
        //     {
        //       title: "Adding the business:",
        //       description: `Step 1: Setup your business location - either by entering the location name in the blank space or moving the marker on the map.
        //             \n Step 2: Setup the entry location of your business location – move and place the marker on the map.
        //             \n Step 3: Manually save the address of the business location – remember, this address is visible to the user on the user app. So make sure to enter the proper address. 
        //             \n Step 4: Select the type of parking space available in your premises – 2 Wheeler/4 Wheeler, select both if you have both types of parking spaces available. And define the number of slots available for each type (for e.g. 12 – 2 Wheeler slots / 15 – 4 Wheeler slots).
        //             \n Step 5: Define the pricing of each slot for One Hour – this will be shown to the user as the minimum price for 1 hour. The total amount the user shall pay at the time of check-out is calculated based on the number of hours he/she has used the parking space for.
        //             \n Step 6: Define whether there is a charging facility for either type of vehicle (2 wheeler / 4wheeler) or both in your parking space and the number of total slots with charging facility. Skip this \n step if you don’t have a charging facility at your parking location, although selecting charging availability for either type of vehicle will further ask you to define the pricing. 
        //             Step 7: Define the pricing for the usage of the charging facility on an hourly basis, this amount will be added along with the base parking price set by you on the previous page. 
        //             For e.g. 
        //                 •	Base parking price = Rs 30/Hr, 
        //                 •	vehicle charging price = Rs 25/Hr 
        //              The total amount to be paid = 55/Hr

        //             Note: The values shown above are solely for example purposes only, only you shall possess the authority to set the pricing for your parking slot and charging facility usage.
        //             Step 8: Save a catchy name for your business. 
        //             Reminder! The name you save here will be displayed on the user app as your business name, so choose a nice name wisely.
        //             Congratulations! You successfully listed your business. Now sit back and relax, and let BREEZE do the rest of the job. You can monitor and operate your business any time from the drive-in dashboard, don’t forget to activate your slot in ‘manage your slot’ option.`
        //     },
        //     {
        //       title: "Business dashboard: ",
        //       description: `You shall see all your businesses along with the address listed in a grid view, click on the specific Business/Parking location to explore the range of actions you can take for the business/Parking location.
        //             On clicking any business, you can view and modify every detail of your enlisted business any number of times. Isn’t this the best part of self-onboarding you have all the liberty to change or modify your business as per your wish and will. 
        //             From viewing the ‘Transactions of last seven days’ to modifying the CUG (closed user group) function, you have it all on this page. 
        //             Read further to understand how it works - 
        //             `
        //     },
        //     {
        //       title: "LAST SEVEN DAYS TRANSACTIONS:",
        //       description: `All the transactions – the cash collected by your security guard and the amount paid online from the last seven days is laid out for you in the form of a bar graph because we find understanding a bar graph is very easy.
        //             You can monitor this bar graph to get an idea of how your business is doing, gives you an insight if there are any amendments to be made to increase your revenue generation. 
        //             Compare your business on different days, see when your business does the best. This will help you in modifying the surge prices on peak days.`
        //     },
        //     {
        //       title: "OVERVIEW",
        //       description: `Timing: This shows the time of availability of your parking slot. 
        //             Security guards: This shows the number of security guards you have added on duty for your parking lot. 
        //             Four wheeler slots: The number of four-wheeler slots available at your business location. 
        //             Four wheeler w/charging: The number of four-wheeler slots available with charging facility at your business location.`
        //     },
        //     {
        //       title: " Two-wheeler slots",
        //       description: `The number of two-wheeler slots available at your business location.`
        //     },
        //     {
        //       title: "Two-wheeler w/charging:",
        //       description: `The number of two-wheeler slots available with charging at your business location. `
        //     },
        //     {
        //       title: "Manage security:",
        //       description: `This functionality helps you monitor the security guards on duty; you can add or delete the security guard making him ‘on duty’ or ‘off duty’.
        //             Please note that the security guard can log-in to the security app only if the admin (you) adds his number in ‘Manage security’. `
        //     },
        //     {
        //       title: "Adding security: ",
        //       description: `•	Enter security number
        //             •	Enter security name
        //         Click on the ‘+ Add security’ button to add the security guard on duty. 
        //         Below is the list of security guards you have added on duty.`
        //     },
        //     {
        //       title: "Manage parking lot:",
        //       description: `You can manage your parking slot from here, 
        //             Enable or disable your parking lot according to the availability of the slot. 
        //             Disabling your parking slot will not display your slot on the user app. 
        //             Please note, after successfully onboarding your parking lot you have to enable your parking lot in ‘manage parking lot’
        //             You can delete your parking lot as well. Click on the ‘delete parking your premise’ button to delete your parking slot. `
        //     },
        //     {
        //       title: "Transaction list:",
        //       description: `Having a log of all the transactions of your business is a must, the transaction list here helps you find transactions from any previous day.
        //             Enter the START and END DATE of the list you wish to view and click - APPLY. 
        //              To get a customized list of all the transaction which took place on that specific date. 
        //             You can view the following details for a particular transaction:
        //                 •	Check-in Date 
        //                 •	Check-in Time 
        //                 •	Check – out Date 
        //                 •	Check – out Time
        //                 •	Check-in Security 
        //                 •	Check – out security
        //                 •	Vehicle number 
        //                 •	Amount 
        //                 •	Payment mode `
        //     }
        //   ]
        // },
        {
          name: "How to's",
          data: [
            {
              title: "",
              description: ""
            },
            {
              title: "",
              description: ""
            },
            {
              title: " ",
              description: ""
            },
            {
              title:
                " ",
              description: ""
            },

          ]
        },
        {
          name: "FREQUENTLY ASKED QUESTIONS",
          data: [
            {
              title: "What is iPark?",
              description: `iPark is your parking genie! Rent a parking space when you need it, you can book a parking space from the iPark free app.`
            },
            {
              title: "Where can I download the app?",
              description: `Download the app from the play store or app store, available in android and iOS.`
            },
            {
              title: "How to verify my mobile number or e-mail?",
              description: `We will send you a one time password to your mobile number at the time of registering your number.`
            },
            {
              title: "Should I carry my physical id proof/documents at the time of check-in?",
              description: `Showing a physical id proof at the time of check-in is subject to the mandate set by the parking lot admin, at the time of booking you will be informed to carry your id proof.`
            },
            {
              title: "How to book a parking space?",
              description: `Log on to the iPark app, Enter the area/building name to discover all the available parking space - Choose a convenient parking space and click the book button. Navigate to the location and park your vehicle with a simple QR code scan.`
            },
            {
              title: "How do I locate a parking lot that I have booked?",
              description: `Use the in-app navigation system or reach out to your booking location with the address provided.`
            },
            {
              title: "I'm using a hired vehicle, can I still make a booking?",
              description: 'Yes, you can make a booking as long as you scan the QR code at the time of check-in which is present in the mobile app from which the booking is made.'
            },
            {
              title: "How to identify a parking lot with a charging facility?",
              description: `Locations with charging facility have special icons, look out for the icon at the time booking a parking space.`
            },
            {
              title: "Can I cancel a booking if I change my mind?",
              description: `You can cancel a booking, from your upcoming bookings page in the iPark app.`
            },
            {
              title: "I’m a property owner, how can I partner with iPark?",
              description: `Becoming a business partner for property owners is very easy, just sign-up with your E-mail id and mobile number to self onboard your business in few minutes.`
            }
          ]
        },
        {
          name: "FOR PARKING OWNERS AND MANAGERS",
          data: [
            {
              title: "Link",
              description: "http://pg-app-08nj6r7xmo0oguc0a011fy276dpiwy.scalabl.cloud/"

            },


          ]
        },
        {
          name: "Terms & Conditions",
          type: "tnc",
          data: [
            {

              title: "USER TERMS AND CONDITIONS",
              description: `<p><span style="font-weight: 400;">These terms form a legally binding agreement between Users (&ldquo;User(s), you, your, yourself&rdquo;) and Nethaat interactive solutions Private Limited and/or its subsidiaries and affiliates (&ldquo;iPark, we, us, our&rdquo;). These terms shall govern the use of the website, mobile application, help desk (collectively referred to as &ldquo;iPark Platform&rdquo;) which enables the User to connect with us in relation to the services offered through the iPark Platform ("Services").</span></p>
              <p><span style="font-weight: 700;">PLEASE ENSURE TO READ THESE TERMS CAREFULLY BEFORE ACCESSING, USING, OBTAINING OR AVAILING ANY PRODUCTS OR SERVICES BY iPark.&nbsp;</span></p>
              <p><span style="font-weight: 400;">If you do not agree with these Terms of Use you may refrain from using the iPark Platform and/or Services. These conditions must be read in conjunction with any other applicable terms and conditions governing the use of iPark Platform and Services.</span></p>
              <p><span style="font-weight: 400;">iPark reserves the right to modify these Terms of Use at any time at its sole discretion. It will be your responsibility to keep yourself updated with the Terms of Use from time to time, your continued usage of the iPark Platform and Services would be deemed to be an acceptance of these terms and the modifications thereto.</span></p>
              <p><span style="font-weight: 400;">This document is an electronic record in terms of Information Technology Act, 2000 and rules thereunder as applicable and the provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000.&nbsp;</span></p>
              <p><span style="font-weight: 400;">This electronic record is generated by a computer system and does not require any physical or digital signatures. By clicking on &ldquo;I Agree&rdquo; button, you agree to comply with and be bound by these Terms.&nbsp;</span></p>
              <p><strong>Table of Contents</strong></p>
              <ol style="list-style-type: lower-roman;">
                <li>Scope of Services</li>
                <li>Modification of these Terms</li>
                <li>Definitions</li>
                <li>Registration and Account</li>
                <li>Terms specific for Users &ndash;
                  <ul>
                    <li>Eligibility</li>
                    <li>Booking of Services&nbsp;</li>
                    <li>Confirmation of Booking</li>
                    <li>Payment for Services</li>
                    <li>Booking modification and cancellation&nbsp;</li>
                    <li>Responsibilities of the User</li>
                  </ul>
                </li>
                <li>Terms specific for Parking Lot Owners &ndash;
                  <ul>
                    <li>Terms applicable to all Listing</li>
                    <li>Third Parties</li>
                    <li>Admin</li>
                    <li>Service Fees</li>
                    <li>Security</li>
                    <li>Representation and warranties</li>
                    <li>Mobile number check-in</li>
                  </ul>
                </li>
                <li>Term and Termination&nbsp;</li>
                <li>Usage terms</li>
                <li>Prohibited Content</li>
                <li>Communications</li>
                <li>Third Party Links</li>
                <li>Intellectual Property Ownership</li>
                <li>Indemnification</li>
                <li>No warranty</li>
                <li>Limitation of Liability</li>
                <li>Governing Law and Jurisdiction</li>
                <li>Miscellaneous </li>
              </ol>
              <ol className="keypoints">
                <li className="keylist"><strong>Scope of Services</strong>
                  <ol className="keypoints">
                    <li className="keylist">The Platform is an online marketplace that enables the registered owners who offer services
                      (&ldquo;<strong>Parking Lot Owner</strong>&rdquo;) in relation to a parking space for vehicles
                      (&ldquo;<strong>Parking Slot</strong>&rdquo;), publish such Parking Slot on the Platform
                      (&ldquo;<strong>Listing</strong>&rdquo;) and communicate and transact directly with the registered persons
                      seeking to book such parking space (&ldquo;<strong>User</strong>&rdquo;). The Parking Lot Owners and Users,
                      being registered on the Platform are collectively referred as the &ldquo;<strong>Members</strong>&rdquo;.</li>
                    <li className="keylist">As the provider of the Platform, we do not own, create, sell, resell, provide, control, manage, offer,
                      deliver, or supply any Listings or Parking Slot. Parking Lot Owners alone are responsible for their Listing and
                      Parking Slot. When Users make or accept a booking, they are entering into a contract directly with each other.
                      We are not and do not become a party to or other participant in any contractual relationship between Members,
                      nor are we a real estate broker or insurer or an agent in any capacity.</li>
                  </ol>
                </li>
                <li className="keylist"><strong>Modification of these Terms</strong>
                  <ol className="keypoints">
                    <li className="keylist">We reserve the right to modify these Terms at any time in accordance with this provision. If we make changes
                      to these Terms, we will post the revised Terms on the Platform. We shall not notify the Members of any changes
                      made to these Terms. The revised Terms shall be made available on the Site, which the Members are requested to
                      regularly visit to view the most current Terms.</li>
                    <li className="keylist">If any Member disagrees with the revised Terms, such Member may choose to terminate this Agreement with
                      immediate effect. If any Member does not terminate their Agreement before the date the revised Terms become
                      effective, their continued access to or use of the Platform will constitute acceptance of the revised Terms.
                    </li>
                  </ol>
                </li>

                <li className="keylist"><strong>Definitions</strong>
                  <p>All of the defined and capitalized terms in these Terms will have the meaning assigned to them here below:</p>
                  <ol style="list-style-type: lower-roman;">
                    <li className="romanlist">&ldquo;Booking&rdquo; shall mean the allotted request placed by the User on the Platform to avail the Service
                      offered by the Parking Lot Owner.&nbsp;</li>
                    <li className="romanlist">&ldquo;Cancellation Fee&rdquo; shall mean a fee charged when the User or the Parking Lot Owner cancels the
                      booking and shall not exceed the total fee payable by the User.&nbsp;</li>
                    <li className="romanlist">&ldquo;Drive-in/drive-in&rdquo; shall have the meaning given to in Clause 5.2.2 A.</li>
                    <li className="romanlist">&ldquo;Parking Lot Owner&rdquo; shall mean the person who has the necessary legal right to offer Parking Slot
                      on the Platform and shall include the following persons:-&nbsp;
                      <ul style="list-style-type: circle;">
                        <li className="circlelist">An individual who is an owner of a standalone residential or commercial establishment.&nbsp;</li>
                        <li className="circlelist">An individual who represents interests of a Third Party.</li>
                        <li className="circlelist">A co-operative society/entity and the members of such co-operative society/entity.&nbsp;</li>
                      </ul>
                    </li>
                    <li className="romanlist">&ldquo;Parking Slot&rdquo; shall mean the parking space offered by the Parking Lot Owner for use by the User.
                    </li>
                    <li className="romanlist">&ldquo;Registration Data&rdquo; shall mean and may include the present, valid, true and accurate name, email
                      address, phone number, photo identification and such other information as may be required by us from the Members
                      from time to time for the purposes of registration on the Platform.</li>
                    <li className="romanlist">&ldquo;pre - Booking&rdquo; shall have the meaning given to in Clause 5.2.2 B.</li>
                    <li className="romanlist">&ldquo;parking attendants&rdquo; shall mean such person who shall be the caretaker of the Parking Slot and
                      shall be assigned by a particular Parking lot/Parking Lot Owner to execute check-in and check-out operations of
                      users to/from a parking lot.</li>
                    <li className="romanlist">&ldquo;Terms&rdquo; shall mean the terms and conditions as set out in this agreement.</li>
                    <li className="romanlist">&ldquo;Third party&rdquo; shall mean the actual owner of a parking slot and who gives the legal title of
                      ownership for the purposes of the Platform to the Parking Lot Owner.</li>
                    <li className="romanlist">&ldquo;Check-in with mobile number&rdquo;</li>
                    <li className="romanlist">Check-in with mobile number will be applicable for users not using iPark platform, the security, at the time
                      of check-in and check-out shall register the users mobile number in their application.</li>
                    <li className="romanlist">Mobile number check-in user is also equally liable to the the platform&rsquo;s terms of use as mentioned
                      herein. Registering the moile number at the time of check-in will be deemed to be be an acceptance of these
                      terms and the modifications thereto.</li>
                  </ol>
                </li>
                <li className="keylist"><strong>Registration and Account</strong>
                  <ol className="keypoints">
                    <li className="keylist">All Members must register an account ("<strong>Account</strong>") to access and use features of the Platform by
                      entering their Registration Data. The Parking Lot Owners are required to register on the Site and the Users are
                      required to register on the Application.</li>
                    <li className="keylist">Members shall ensure that the Registration Data provided by the Member is accurate, complete, current, valid and
                      true and is updated from time to time. We shall bear no liability for false, incomplete, old or incorrect
                      Registration Data provided by the Member.</li>
                    <li className="keylist">Such Registration Data are required to be verified by generation of a one-time password at the Members
                      end.&nbsp;</li>
                    <li className="keylist">It is at the discretion of the Parking Lot Owner to lay down whether compulsorily original/copy of photo
                      identification would be required to be shown by the User at the time of checking &ndash;in to the Parking lot, for
                      security reasons. In cases where Parking Lot Owners specifically make such a requirement, then that User will not
                      be allowed to use the Parking Slot without showing a proof of the original photo identification at the entry gate
                      of the Parking Slot.&nbsp;</li>
                    <li className="keylist">Specifically, the following details are required for registration of the Parking Lot Owners:-&nbsp;
                      <ol style="list-style-type: lower-roman;">
                        <li className="romanlist">Bank account details of the Parking Lot Owner for the purpose of verification;</li>
                        <li className="romanlist">Location of the Parking Slot along with the entry and exit gate;</li>
                        <li className="romanlist">Whether it is an Drive-in or a pre-booking;</li>
                        <li className="romanlist">Whether the parking is available for a four wheeler or a two wheeler (only in the case of Drive-in);</li>
                        <li className="romanlist">The number of Parking Slots made available by the Parking Lot Owner; and</li>
                        <li className="romanlist">The pricing structure for each Parking Slot (specifying minimum price chargeable for the first hour and the
                          price for every hour subsequently). The structure must also include the price chargeable for weekday and
                          weekend.&nbsp;</li>
                      </ol>
                    </li>
                    <li className="keylist">Members cannot register more than one Account in association with the Registration Data provided by the Member
                      on the Platform and such Account cannot be assigned or otherwise transferred to another person.</li>
                    <li className="keylist">Members are solely responsible for maintaining the confidentiality and security of their Registration Data.
                      Members may be held liable for any and all activities and transactions that occur through their Account, unless
                      such activities are not authorized by the Member or the Member is not otherwise negligent. In case of any
                      unauthorized use of the Members Account or an issue regarding access to account, please reach us immediately at
                      our help desk. </li>
                  </ol>
                </li>
                <li className="keylist"><strong>Terms specific for Users</strong>
                  <ol className="keypoints">
                    <li className="keylist"><strong>Eligibility</strong>
                    <p>In order to access and use the Platform or register an Account, the Member must be an individual of 18 years old and above and capable of entering, performing and adhering to these Terms.&nbsp;</p>
                    </li>
                    <li className="keylist"><strong>Booking of Services&nbsp;</strong>
                    <ol className="keypoints">
                      <li className="keylist">Once the User is registered on the Platform, the User is automatically directed to the Parking Slots available near such User&rsquo;s exact GPS location. The User may also be able to book the Parking Slots available in another location by entering the GPS location of that place. Each Parking Slot shall specifically indicate whether original photo identification is compulsorily required to be shown at the entry gate of the Parking Slot.&nbsp;</li>
                      <li className="keylist">In order to use the Parking Slot, the User will be able to do so by choosing one of two methods of booking:-&nbsp;
                        <p></p>
                        <p>A. <strong>Drive-in</strong></p>
                      </li>
                      <li className="keylist">If the User chooses Drive-ins/drive-in, the User will be able to view the available Parking Slots for that day itself. Accordingly, the User may select the desired Parking Slot and the time.</li>
                      <li className="keylist">Once User has selected the required Parking Slot and time for a particular date, he/she can check-in to the Parking lot.</li>
                      <li className="keylist">the User&rsquo;s booking will be confirmed and the User will have entered a binding agreement with the Parking Lot Owner for the use of Parking Slot after a check-in to the parking lot.&nbsp;</li>
                      <li className="keylist">The user shall be bound to pay the amount charged for usage of parking lot at the time of check-out after scanning the QR code.
                      <p></p>
                      <p>B. <strong>Pre-Booking</strong></p></li>
                      <li className="keylist">When User wants to book a Parking Slot in advance at a desired date and time, the pre-bookings option allows the User to do so.</li>
                      <li className="keylist">Users may select their desired date and time (maximum upto 1 day in advance) on the Listing page for pre-booking the Parking Slot.&nbsp;</li>
                      <li className="keylist">Since the Parking Slot is available by the Parking Lot Owner for a fixed period of time, the booking of the Parking Slot is based on the availability of the Parking Slot by the Parking Lot Owner for the requested period. A User may book a Parking Slot only if such Parking Slot is made available by the Parking Lot Owner on the Platform.&nbsp;</li>
                      <li className="keylist">Once we receive the payment from the User and the User accepts the Terms and any additional terms for the Parking Slot that are set out to use the Parking Slot, the User shall be said to have entered a binding agreement with the Parking Lot Owner for the use of Parking Slot.&nbsp;</li>
                      <li className="keylist">The User cannot use the Parking Slot past the check-out as specified by the Parking Lot Owner on the Platform. The User agrees to leave the Parking Slot no later than the check-out time as specified by the Parking Lot Owner on the Platform.&nbsp;
                      <p></p>
                        <p>C. <strong>Check-in with mobile number</strong> </p>
                      </li>
                      <li className="keylist">Check-in with mobile number will be applicable for users not using iPark platform, the security, at the time of check-in and check-out shall register the users mobile number in their application.</li>
                      <li className="keylist">Mobile number check-in user is also equally liable to the platform&rsquo;s terms of use as mentioned herein. Registering the mobile number at the time of check-in will be deemed to be an acceptance of these terms and the modifications thereto.</li>
                    </ol>
                    </li>
                    <li className="keylist"><strong>Confirmation of Booking</strong>
                    <p>We shall, upon receiving the booking request from the User in the manner set out above, proceed to confirm or decline the booking based on the availability of Parking Slots at the time of booking, which shall be informed to the User vide text message or email. In the event the booking is confirmed, the User shall check the booking details including but not limited to check in time and location of the Parking Slot.</p>
                      <ol className="keypoints">
                        <li className="keylist">Online payments may be made by use of PayTm. In order to make the online payment, the User must agree and undertake to share the relevant paytm account details with us for the successful completion of total payment towards the Parking Slot. Any payment related issue, except when such issue is due to an error or fault in the Platform, shall be resolved between the User and the concerned payment processor. We shall not be responsible for any unauthorized use of the User&rsquo;s payment during or after using the Parking Slot on the Platform.</li>
                        <li className="keylist">We are authorized by the Parking Lot Owner to accept payment from the User on behalf of the Parking Lot Owner.&nbsp;</li>
                      </ol>
                    </li>
                    <li className="keylist"><strong>Booking modification and cancellation&nbsp;</strong>
                      <ol className="keypoints">
                          <li className="keylist">Once a Parking Slot which has been offered by a Parking Lot Owner has been partly or fully booked by the User for a particular timing, then the Members shall not be allowed to modify the available timing of such Parking Slot.&nbsp;</li>
                          <li className="keylist">The User agrees and acknowledges that User may cancel his booking till the booking begins , however, cancellation of booking after the booking has begun is subject to a Cancellation Fee charged by us on the User upon cancellation of booking.&nbsp;</li>
                          <li className="keylist">A receipt of the Cancellation Fee will be provided by us. The Cancellation Fee shall be payable by the User at the completion of the User&rsquo;s subsequent booking on the Platform.</li>
                          <li className="keylist">Under the condition, if the user exceeds, breaches, surpass the time of check-in, the booking shall be cancelled levying respected cancellation charges.&nbsp;</li>
                      </ol>
                    </li>
                    <li className="keylist"><strong>Responsibilities of the User</strong>
                    <ol className="keypoints">
                      <li className="keylist">By using the Application or the Service, the User agrees that:&nbsp;
                        <ol style="list-style-type: lower-roman;">
                          <li className="romanlist">The User will only use the Service or download the Application for their sole, personal use and will not resell it;&nbsp;</li>
                          <li className="romanlist">The User will not use an account that is subject to any rights of a person other than the User without appropriate authorization;&nbsp;</li>
                          <li className="romanlist">The User will not use the Service or Site for unlawful purposes;&nbsp;</li>
                          <li className="romanlist">The User will not try to harm the Service, Site or our network in any way whatsoever;&nbsp;</li>
                          <li className="romanlist">The User will provide us with such information and documents which we may reasonably request;&nbsp;</li>
                          <li className="romanlist">The User will only use an authorized network to avail the Service;&nbsp;</li>
                          <li className="romanlist">The User will comply with all applicable laws from the User&rsquo;s country of domicile and residence and the country, state and/or city in which the User is present while using the Site or Service; and&nbsp;</li>
                          <li className="romanlist">The User is aware of and shall comply with the Information Technology Act, 2000 and the rules, regulations and guidelines notified thereunder.</li>
                        </ol>
                      </li>
                      <li className="keylist">The User shall be responsible for leaving the Parking Slot in the same condition the way it was when the User arrived. The User will be responsible for his own acts and omissions and will also be responsible for the acts and omissions of any individuals whom he invites to, or otherwise provide access to, in the Parking Slot, excluding the Parking Lot Owner (and the individuals the Parking Lot Owner invites to the Parking Slot, if applicable).</li>
                      <li className="keylist">If the Parking Lot Owner claims and provides evidence that the User has damaged the Parking Slot, the Parking Lot Owner can seek payment from the User. If the same is escalated by the Parking Lot Owner to us, then we shall give an opportunity to both the User and the Parking Lot Owner to respond. We may at our sole discretion decide whether the User or the Parking Lot Owner is responsible for the damage caused.</li>
                      </ol>
                    </li>
                  </ol>
                  </li>
                  <li className="keylist"><strong>Terms specific for Parking Lot Owners</strong>
                    <ol className="keypoints">
                      <li className="keylist"><strong>Terms applicable to all Listing</strong>
                      <ol className="keypoints">
                        <li className="keylist">We shall procure reasonable efforts to bring the User into contact with the Parking Lot Owner, subject to the availability of Parking Lot Owner in or around the User&rsquo;s location at the moment of the User requests for such services.</li>
                        <li className="keylist">In case of a standalone residential or commercial premise, or co-operative society of a residential or a commercial premise, the co-operative society must register itself on the Site and then allow the member of such co-operative society to register on the Site. The member of a co-operative society cannot register on the Site unless the co-operative society allows it to do so.</li>
                        <li className="keylist">The Parking Lot Owners may use the facility of Drive-ins/drive-in to offer a single Parking Slot or even multiple Parking Slots. In either of the case, the Parking Lot Owner prescribes the identity of the parking attendants along with their phone numbers, who would be present at the entry and exit gate.</li>
                        <li className="keylist">In case of Scheduled/pre - Bookings, the Parking Lot Owner must prescribe the number of Parking Slots available with respect to both four wheeler and two wheeler vehicles. In case where the Parking Lot Owner acts on behalf of the Third Parties, the Parking Lot Owner must then specify the identity of the Third Parties along with their phone numbers.&nbsp;</li>
                        <li className="keylist">The Parking Lot Owner may prescribe its own specific terms and condition for the Users, some of which may be reflected on the Parking Lot Owner&rsquo;s premises. These terms may include whether the User has to compulsorily be outside the Parking Slot once he has parked the vehicle, whether smoking or drinking is allowed in the Parking Slot, whether pets are allowed in the Parking Slot etc.&nbsp;</li>
                        </ol>
                      </li>
                      <li className="keylist"><strong>Third Parties</strong>
                      <ol className="keypoints">
                        <li className="keylist">A Third Party may use the Platform on the Application wherein each Parking Slot owned by a Third Party is displayed and the Third Party may choose the particular Parking Slot which he wants to access at a particular time.&nbsp;</li>
                        <li className="keylist">A Third Party may adjust the availability of each of his Parking Slots as per his convenience.</li>
                        <li className="keylist">A Third Party can be owner of multiple Parking Slots in multiple co-operative societies and may also be a Parking Lot Owner, as the case may be.&nbsp;</li>
                        <li className="keylist">The registration of a Third Party is done as per Clause 4 of this Agreement.&nbsp;</li>
                        </ol>
                      </li>
                      <li className="keylist"><strong>Admin</strong>
                      <ol className="keypoints">
                        <li className="keylist">We enable Parking Lot Owners to authorize other Members (&ldquo;Admin&rdquo;) to administer the Parking Lot Owner&rsquo;s Listing on the Platform, and to bind the Parking Lot Owner and take certain actions in relation to the Listing as permitted by the Parking Lot Owner. For instance, in the case of a co-operative society, an Admin can be a related person of the Parking Lot Owner once approved by the society.</li>
                        <li className="keylist">We reserve the right, in our sole discretion, to limit the number of Admins a Parking Lot Owner may invite for each Listing and to limit the number of Listings an Admin may manage.</li>
                        <li className="keylist">Parking Lot Owners should exercise due diligence and care when deciding who to add as a Admin to their Listing. Parking Lot Owners remain solely responsible and liable for any and all Listings published on the Platform, including any Listing created by an Admin on their behalf. Admins remain responsible and liable for their own acts and omissions when engaging in their roles and responsibilities as a Admin, including, but not limited to, conduct that causes harm or damage to the Parking Lot Owner. In addition, both Parking Lot Owner and Admin are jointly responsible and severally liable for third party claims, including User claims, arising from the acts and omissions of the other person.</li>
                        <li className="keylist">Parking Lot Owner and Admin may enter into an agreement which may be terminated at any time. We may terminate the Parking Lot Owner and Admin&rsquo;s participation in providing the Parking Slot. When a Member is removed as a Admin, that Member will no longer have access to any Parking Lot Owner or User information on the Platform.</li>
                        </ol>
                      </li>
                      <li className="keylist"><strong>Service Fees</strong>
                      <ol className="keypoints">
                        <li className="keylist">Before the entire payment received from the User is remitted to the Parking Lot Owner, we shall deduct Service Fees from the payment received by the User. This Service fees shall be determined and amended at our sole and absolute discretion based on the jurisdiction of the Parking Slot. This fee shall be defined either by minimum per month fee or by percentage.</li>
                        <li className="keylist">This fee shall be informed to the Parking Lot Owner before he provides the Parking Slot to the User. We reserve the right to change the Service Fees at any time, and will provide the Members adequate notice of any fee changes before they become effective. Such fee changes will not affect any bookings made prior to the effective date of the fee change.</li>
                        <li className="keylist">We shall provide a receipt of the total fee payable by the User at the end of the booking</li>
                        <li className="keylist">In the case where Third Parties exist, the Parking Lot Owner must specify the amount that shall be retained by the Parking Lot Owner out of its aggregate collection and the remaining shall be given to such Third Parties. The amount collected by the platform will be transferred to the Parking Lot Owner in a way that a programme shall be run on a weekly basis such that we shall have information about all the Parking Slots held by each of the Third Parties. Out of the total collection made, we shall retain a fixed percentage every week and the remaining amount shall be transferred to the Third Parties by electronic bank transfer.</li>
                        <li className="keylist">In the case of a pre-booking, we as well as the Parking Lot Owner retains a certain percentage out of the total collection being remitted to the Third Parties. The remaining amount shall be distributed on a pro-rata basis amongst the Third Parties on basis of the average time the Parking Slots have been used.</li>
                        </ol>
                      </li>
                      <li className="keylist"><strong>Security</strong>
                      <ol className="keypoints">
                        <li className="keylist">For the purpose of security, the parking attendants which are prescribed by the Parking Lot Owner to be present at the entry and the exit gate must be registered on the Platform through Application.</li>
                        <li className="keylist">We will not provide the parking attendants with digital tablet or any other device that may be necessary to manage the check-in and check-out of the User. A smartphone necessary for check-in and check-out operations shall be taken care of on their own or the parking attendant shall ask for a smartphone from the parking lot owner.</li>
                        <li className="keylist">The parking attendant must provide their Registration Data in order to register on the Platform and to identify the appropriate Parking Lot Owner for the parking attendant.</li>
                        <li className="keylist">The Platform prohibits the same person to sign in as parking attendant in two different Parking Slots at the same time.</li>
                        <li className="keylist">The platform allows only those parking attendants, which are added by the parking lot owner or admin in the iPark website, to log-in to the iPark POS app. The parking attendant will be restricted from log-in if the parking lot owner or admin has not added the parking attendant name and number in that particular parking lot.</li>
                        <li className="keylist">Once the User enters the parking lot, the parking attendant shall scan the QR code of the User from his Application to identify the User. This would also enable the parking attendant to know whether it is a Drive-in or a pre-booking. In case compulsorily photo identification is required, then the parking attendant should allow the User to enter the Parking Slot only if such original photo identification is shown.</li>
                        <li className="keylist">The time when the parking attendant checks-in a user, the entry time gets recorded on the Application. The parking attendant must inform the User about the location of the Parking Slot, according to which the User shall park his vehicle.</li>
                        <li className="keylist">Another parking attendant may also check-out the User at the exit gate. While checking-out, if the User chooses to make the payment by cash, then the exact cash must be handed by the User to the parking attendant.&nbsp;</li>
                        </ol>
                      </li>
                      <li className="keylist"><strong>Representation and warranties</strong>
                      <ol className="keypoints">
                        <li className="keylist">The Parking Lot Owner represents and warrants that:
                        <ol style="list-style-type: lower-roman;">
                          <li className="romanlist">The Parking Lot Owner is eligible for registration on the Site/Application and creation of an account on the Platform in terms of these Terms.</li>
                          <li className="romanlist">The Parking Lot Owner has all requisite powers and authority to, deliver and perform the obligations imposed herein;</li>
                          <li className="romanlist">The execution and performance of the obligations do not and will not violate any provision of any existing agreement, law, rule, regulation, any order or judicial pronouncement to which the Parking Lot Owner is a party;</li>
                          <li className="romanlist">The Parking Lot Owner is the rightful owner of the vehicle or has the requisite authority or assignment to drive the vehicle and there are no restrictions with respect to the use of the vehicle that will hinder the Parking Lot Owner from the performance of the Services;</li>
                          <li className="romanlist">The Parking Lot Owner has all rights, licenses and permits as may required by applicable Laws to perform the Service in accordance with these Terms. The Parking Lot Owner hereby represents that he shall maintain and continue to maintain all local licenses, permits, approval and consents in respect to the parking lot. The Parking Lot Owner shall be responsible and liable for any violation of any law, rule or regulation in the performance of its obligations under these Terms.</li>
                          <li className="romanlist">The Parking Lot Owner has not been convicted by any court in India or any other country of any crimes including but not limited to involving moral turpitude. Further, the Parking Lot Owner is not a party to any pending litigation which shall materially affect his obligations under these Terms.</li>
                          </ol>
                        </li>
                        <li className="keylist">The Parking Lot Owner shall undertake that, at all times during the term of this Agreement, the Parking Lot Owner will:
                        <ol style="list-style-type: lower-roman;">
                          <li className="romanlist">abide by these Terms and any other policies as may be made applicable to the Parking Lot Owner from time to time;</li>
                          <li className="romanlist">perform the Service/(s) in accordance with all applicable laws;</li>
                          <li className="romanlist">not violate our intellectual property rights or of any third party and for any breach or violation of such intellectual property rights; and be solely responsible to comply with our policies and adopt appropriate processes to prevent offering any illegal gratification in the form of bribes or gifts either in cash or in kind in the course of all dealings with us or the User or any other third parties.</li>
                          </ol>
                        </li>
                        </ol>
                      </li>
                    </ol>
                    </li>
                    <li className="keylist"><strong>Term and Termination</strong>
                      <ol className="keypoints">
                        <li className="keylist">This Agreement shall be effective for a period of 12 months, at the end of which it will automatically and continuously renew for subsequent 12 months until such time when either of the party to the agreement terminates the Agreement in accordance with this provision.</li>
                        <li className="keylist">The said Agreement may be terminated by the User at any time by sending an e-mail specifying that the User wishes to discontinue the service or terminate the Agreement. The Parking Lot Owner shall also be entitled to terminate this Agreement by assigning reasons for the termination.</li>
                        <li className="keylist">We reserve the right to terminate this Agreement for the use of the Platform by the User, without giving any notice or reason for the same. Further, we shall not incur liability for breach of terms and conditions if we terminate the same. We shall also be entitled to terminate this Agreement immediately for breach of any terms of this Agreement by the Parking Lot Owners.</li>
                      </ol>
                      </li>
                      <li className="keylist"><strong>Usage terms</strong>
                      <ol className="keypoints">
                        <li className="keylist">The information, materials, Services available on the Platform may inadvertently include inaccuracies, typographical errors, or outdated information. We are not responsible for and shall not be bound to honor typographical or pricing errors on the Platform.&nbsp;</li>
                      <li className="keylist">We reserve the right to refuse or cancel bookings at any time, including but not limited to the bookings that contain incorrect prices or Parking Slot descriptions, bookings in which we believe the User has violated applicable laws or these Terms, bookings that we believe are harmful to us or bookings that we believe are fraudulent or based on illegal, fraudulent or deceitful use/furnishing of information or based on false information.&nbsp;</li>
                        <li className="keylist">We neither warrant nor make any representations regarding the quality, accuracy or completeness of any data, information, product or Service. We expressly disclaim any warranties whether express or implied about the accuracy, completeness, correctness, suitability, reliability, availability, timeliness, quality, continuity, performance, error free or uninterrupted operation/functioning, fitness for a particular purpose, workmanlike effort, non-infringement, lack of viruses or other harmful components of the Services.</li>
                        <li className="keylist">We shall not be responsible for the delay or inability to use the Platform unrelated functionalities, the provision of or failure to provide functionalities, or for any information, software, Services, functionalities and related graphics obtained through the Platform, or otherwise arising out of the use of the Platform, whether based on contract, tort, negligence, strict liability or otherwise.</li>
                        <li className="keylist">Further, we shall not be held responsible for non-availability of the Platform during periodic maintenance operations or any unplanned suspension of access to the Platform that may occur due to technical reasons or for any reason beyond our control.&nbsp;</li>
                        <li className="keylist">The User understands and agrees that any material or data downloaded or otherwise obtained through the Platform is done entirely at their own discretion and risk and they will be solely responsible for any damage to their computer systems or loss of data that results from the download of such material or data. We accept no liability for any errors or omissions, with respect to any information provided to the User.</li>
                        </ol>
                      </li>
                      <li className="keylist"><strong>Prohibited Content&nbsp;</strong>
                      <ol className="keypoints">
                        <li className="keylist">As a pre-condition of use of the Platform, User warrants to us that User shall not use this Platform for any purpose that is unlawful, unauthorized, or inconsistent with these terms, and the User agrees that this license to use Platform will terminate immediately upon User's violation of this warranty. We reserve the right, at its sole discretion, to block/terminate User's access to this Platform and its content at any time, with or without notice.&nbsp;</li>
                        <li className="keylist">The User agrees, acknowledges, confirms and undertakes that the registration data, information/data provided or uploaded onto the Platform by the User:
                        <ol style="list-style-type: lower-alpha;">
                          <li className="romanlist">shall not be false, inaccurate, misleading or incomplete; or</li>
                          <li className="romanlist">shall not be fraudulent or involve the use of counterfeit or stolen Credit Cards; or</li>
                          <li className="romanlist">shall not infringe any third party's intellectual property, trade secret or other proprietary rights or rights of publicity or privacy; or</li>
                          <li className="romanlist">shall not be defamatory, libelous, unlawfully threatening or unlawfully harassing; or</li>
                          <li className="romanlist">shall not contain any viruses, Trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines or executable files that may damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data or personal information of any person whatsoever; or</li>
                          <li className="romanlist">shall not create liability for us or cause us to lose (in whole or in part) the services of our ISPs or other service providers/suppliers. If the User contravenes the foregoing or we have reasonable grounds to suspect that the User has contravened the foregoing, we have the right to indefinitely deny or terminate User's access to the Platform and to refuse to honor the User's request(s).</li>
                          </ol>
                        </li>
                        <li className="keylist">The Users are prohibited from engaging in the following activities:
                        <ol style="list-style-type: lower-alpha;">
                          <li className="romanlist">Systematically retrieve data or other content from the Platform to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</li>
                            <li className="romanlist">Make any unauthorized use of the Platform, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.</li>
                            <li className="romanlist">Circumvent, disable, or otherwise interfere with security-related features of the Platform, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Platform and/or the content contained therein.</li>
                            <li className="romanlist">Engage in unauthorized framing of or linking to the Platform.</li>
                            <li className="romanlist">Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.</li>
                            <li className="romanlist">Make improper use of our support services or submit false reports of abuse or misconduct.</li>
                            <li className="romanlist">Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</li>
                            <li className="romanlist">Interfere with, disrupt, or create an undue burden on the Platform or the networks or services connected to the Platform.</li>
                            <li className="romanlist">Attempt to impersonate another user or person or use the username of another user.</li>
                            <li className="romanlist">Sell or otherwise transfer your profile.</li>
                            <li className="romanlist">Use any information obtained from the Platform in order to harass, abuse, or harm another person.</li>
                            <li className="romanlist">Use the Platform as part of any effort to compete with us or otherwise use the Platform and/or the content for any revenue-generating endeavor or commercial enterprise.</li>
                            <li className="romanlist">Decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Platform.</li>
                            <li className="romanlist">Attempt to bypass any measures of the Platform designed to prevent or restrict access to the Platform, or any portion of the Platform.</li>
                            <li className="romanlist">Copy or adapt the Platform&rsquo;s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</li>
                            <li className="romanlist">Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party&rsquo;s uninterrupted use and enjoyment of the Platform or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Platform.</li>
                            <li className="romanlist">Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism.</li>
                            <li className="romanlist">Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Platform, or using or launching any unauthorized script or other software.</li>
                            <li className="romanlist">Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Platform.</li>
                            <li className="romanlist">Use the Platform in a manner inconsistent with any applicable laws or regulations.</li>
                          </ol>
                        </li>
                        </ol>
                      </li>
                      <li className="keylist"><strong>Communications</strong>
                        <ol className="keypoints">
                          <li className="keylist">When the Members use the Platform, the Members agree and understand that they are communicating with us through electronic records and consent to receive communications via electronic records from us periodically and as and when required.&nbsp;</li>
                          <li className="keylist">We may communicate with the Members by registered mobile number or registered email address or by such other mode of communication, electronic or otherwise. The Members specifically agree that we shall not be responsible for unauthorized access to or alteration of the Members transmissions or data, any material or data sent or received or not sent or received.&nbsp;</li>
                          <li className="keylist">Further, we will make best efforts to safeguard the confidentiality of the Members personally identifiable information available with it, but transmissions made by means of the internet cannot be guaranteed or made absolutely secure. By using this Platform, the Members agree that we shall not be liable for disclosure of their information due to errors in transmission or unauthorized acts of third parties.&nbsp;</li>
                          <li className="keylist">Without prejudice to the foregoing, the Members agree that we shall not be held liable or responsible for 'phishing attacks' on the Members. The Members may accept or decline the cookies when they accesses Platform. It is the Members responsibility to set their browser to alert him/her to accept or to reject cookies.</li>
                        </ol>
                      </li>
                      <li className="keylist"><strong>Third Party Links</strong>
                        <ol className="keypoints">
                          <li className="keylist">This Platform may at our sole discretion, contain links to Platforms owned and maintained by persons or entities other than us. We may also provide links to the other sites for purpose of enabling the Users to make payment to us. Any of the foregoing links do not constitute an endorsement by us of any such sites and are provided only as a convenience. We are not responsible for the content or links displayed on such sites.&nbsp;</li>
                          <li className="keylist">We are not responsible for the privacy practices of such sites which we do not own, manage or control. We do not regularly review, and make no warranty or representation regarding materials posted, or services offered, on the sites to which this Platform may be linked and we shall not be responsible for any deficiency thereof.</li>
                          <li className="keylist">We do not endorse any or all of the materials, and services available on such linked sites, and we expressly disclaim responsibility for the contents of any linked site, the accuracy of any information contained in a linked site, and the quality of the services offered at any linked site. Any decision to view the contents of any linked site is solely the responsibility of the User and is made at User&rsquo;s own risk.</li>
                        </ol>
                      </li>
                      <li className="keylist"><strong>Intellectual Property Ownership</strong>
                        <ol className="keypoints">
                          <li className="keylist">We alone (and our licensors, where applicable) shall own all right, title and interest, including all related intellectual property rights, in and to
                          <ol className="keypoints">
                              <li className="keylist">the Site, Application, Service and any suggestions, ideas, enhancement requests, feedback, recommendations or any other offering;</li>
                              <li className="keylist"> text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, artwork and computer code; or</li>
                              <li className="keylist">other information provided by You or any other party relating to the Site, Application or the Service. Third party trademarks may appear on this Site/ Application and all rights therein are reserved to the registered owners of those trademarks. For use of any third party's intellectual property, You need to get permission directly from the owner of the intellectual property for any use.</li>
                            </ol>
                          </li>
                          <li className="keylist">These Terms do not constitute a sale and do not convey to You any rights of ownership in or related to the Site, the Application or the Service, or any intellectual property rights owned by us. You shall be solely responsible for any violations of any laws and for any infringements of any intellectual property rights caused by use of the Services or the Site/ Application.</li>
                          <li className="keylist">You may use information on the Site purposely made available by us for downloading from the Site, provided that You:
                        <ol className="keypoints">
                              <li className="keylist">do not remove any proprietary notice language in all copies of such documents and make no modifications to the information;</li>
                              <li className="keylist">use such information only for Your personal, non-commercial informational purpose and do not copy or post such information on any networked computer or broadcast it in any media; or</li>
                              <li className="keylist">do not make any additional representations or warranties relating to such information.</li>
                            </ol>
                          </li>
                        </ol>
                      </li>
                      <li className="keylist"><strong>Indemnification</strong>
                        <p>Without prejudice to and in addition to any other remedies, reliefs or legal recourses available to us herein or any applicable laws or otherwise, the User agrees to indemnify, defend and hold us harmless including but not limited to its affiliate, agents and employees from and against any and all losses, liabilities, claims, damages, demands, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by us that arise out of or related to the User&rsquo;s use or misuse of the Site, any violation by the User of these Terms, or any breach of representations, warranties and covenants made by the User herein.</p>
                      </li>
                      <li className="keylist"><strong>No warranty</strong>
                        <ol className="keypoints">
                          <li className="keylist">The Services are provided by us on an "as is" basis without warranty of any kind, express, implied, statutory or otherwise, including the implied warranties of title, non-infringement, merchantability or fitness for a particular purpose. Without limiting the foregoing, we make no warranty that (i) Platform or the Services will meet the User&rsquo;s requirements or the User&rsquo;s use of the Platform or will be uninterrupted, timely, secure or error-free; (ii) the results that may be obtained from the use of the Platform, or Services will be effective, accurate or reliable; (iii) the quality of the Platform, or Services will meet your expectations; or that (iv) any errors or defects in the Platform or Services will be corrected. No advice or information, whether oral or written, obtained by the Members from us or through or from use of the Platform shall create any warranty not expressly stated in the terms of use. We shall have no liability to the User for any interruption or delay, to access the Platform irrespective of the cause.</li>
                          <li className="keylist">It is hereby further clarified that we and Parking Lot Owners are separate and independent entities and we do not work as representative or agent of the Parking Lot Owners. By making a booking on the Platform, the User enters into commercial/ contractual terms as offered by and agreed to between Parking Lot Owner and the User alone. We are merely an electronic platform to facilitate provision of Services pertaining to parking of vehicles between the Users and the Parking Lot Owners.&nbsp;</li>
                          <li className="keylist">The User hereby assumes the sole risk of booking or making use or relying on the information relating to the Services. It is User&rsquo;s responsibility to check the details of the Listing on the Platform at its sole discretion.&nbsp;</li>
                        </ol>
                      </li>
                      <li className="keylist"><strong>Limitation of Liability</strong>
                        <p>We shall not be liable for any damages of any kind whatsoever including but not limited to direct, indirect, incidental, punitive, exemplary and consequential damages, damages for loss of use, data or profits, or other intangible losses, which may arise or are arising from the use of Platform or any of the information, software, services and related graphics contained within the Platform or any of the Services offered, regardless of whether such damages are based on contract, tort, negligence, strict liability or otherwise, and even if we have been advised of the possibility of damages.</p>
                      </li>
                      <li className="keylist"><strong>Governing Law and Jurisdiction</strong>
                        <ol className="keypoints">
                          <li className="keylist">This Agreement, and all transactions entered into on or through Platform shall be interpreted, construed and governed by the laws of India which shall be applicable to this Agreement without regard to principles of conflict of laws.&nbsp;</li>
                          <li className="keylist">Any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation or validity thereof or the use of the Site, Services or the Application (collectively, &ldquo;<strong>Disputes</strong>&rdquo;) will be subject to the exclusive jurisdiction of courts at Bangalore, India.&nbsp;</li>
                          <li className="keylist">Any Dispute arising from or in connection with this Agreement shall be referred to arbitration under the Arbitration and Conciliation Act, 1996 to be adjudicated by a sole arbitrator appointed by us. The arbitration shall be held in Bangalore and the arbitration proceedings shall be conducted in English. The arbitral award rendered shall be final and binding upon both parties.&nbsp;</li>
                        </ol>
                      </li>
                      <li className="keylist"><strong>Miscellaneous</strong>
                        <ol className="keypoints">
                          <li className="keylist"><strong>Confidentiality</strong>
                            <ol className="keypoints">
                              <li className="keylist">The Members acknowledge that pursuant to these Terms, the Members will have access to our confidential information which has been provided by us. The Members further undertake to keep all confidential data and shall not sell or otherwise make that information available to any third parties.</li>
                              <li className="keylist">Except as otherwise agreed, the data of Users will be our exclusive property, and the Parking Lot Owners will not use the same for their own purpose or distribute such data in any form or means except for the purpose of these Terms and shall keep it confidential at all times. Confidential information would include but not be limited to User details, market information and documents related thereto, the contents of the Site, Application or any other information which is treated as confidential by us, and any other information, whether orally or in writing, received or to be received by the Members which is agreed to be treated as confidential, whether expressly or by implication.</li>
                            </ol>
                          </li>
                          <li className="keylist"><strong>Notice</strong>
                            <p>We may give notice by means of a general notice on the Service or Application, or by electronic mail to the Members email address or a message on the Members registered mobile number. You may contact us by electronic mail at our email address or contact our help desk.</p>
                          </li>
                          <li className="keylist"><strong>Force Majeure</strong>
                            <p>Any delay in or failure to perform any obligations by any Member under the Terms shall not constitute default hereunder if and to the extent caused by force majeure, which is defined to be occurrences beyond the reasonable control of such Member committing default, including and limited to acts of the government authorities, acts of god, fire, flood, explosion, riots, war, rebellion, insurrection etc.</p>
                          </li>
                          <li className="keylist"><strong>No Waiver&nbsp;</strong>
                            <p>Any failure, delay or forbearance on our part in: (i) exercising any right, power or privilege under this Agreement; or (ii) enforcing terms of this Agreement, shall not operate as a waiver thereof, nor shall any single or partial exercise by us of any right, power or privilege preclude any other future exercise or enforcement thereof.</p>
                          </li>
                          <li className="keylist"><strong>Conflict</strong>
                            <p>In the event of any contradiction or inconsistency between these Terms and any other agreement executed between the Members and us, these Terms shall prevail unless the exception has been expressly agreed to in writing by making reference to the relevant clause sought to be modified under these Terms.</p>
                          </li>
                          <li className="keylist"><strong>Severability</strong>
                            <p>The Members hereto agree that each of the provisions contained in this Agreement shall be severable, and the unenforceability of one or more provisions of this Agreement shall not affect the enforceability of any other provision(s) or of the remainder of this Agreement.</p>
                          </li>
                        </ol>
                      </li>
                      <li className="keylist"><strong> General Terms and Conditions</strong>
                        <p>It is mandatory for visitors to present valid photo identification at the time of check-in if specified by the parking lot owner. According to government regulations, a valid Photo ID has to be carried by every person above the age of 18. The identification proofs accepted are Aadhar Card, Driving License, Voter ID Card, and Passport. Without Original copy of the valid ID the guest will not be allowed to check-in.</p>
                      </li>
                      <li className="keylist"><strong> Cancellation and refund Policy</strong>
                        <p>We would love to host you but in case your plans change, our simple cancellation process makes sure you receive a quick confirmation and fast refunds.</p>
                        <p>We do not charge cancellation fees for bookings cancelled till the time a booking starts. Any amount paid will be refunded.</p>
                      </li>
              </ol>
              `
            }
          ]
        },
        {
          name: "Privacy Policy",
          type: "pripol",
          data: [{
            title: "Your website may use the Privacy Policy given below:",
            description: `
            <p>The terms "We" / "Us" / "Our"/&rdquo;Company/iPark platform&rdquo; individually and collectively refer to Nethaat interactive solutions private limited and the terms "You" /"Your" / "Yourself" refer to the users.&nbsp;</p>
            <p>This Privacy Policy is an electronic record in the form of an electronic contract formed under the information Technology Act, 2000 and the rules made thereunder and the amended provisions pertaining to electronic documents / records in various statutes as amended by the information Technology Act, 2000. This Privacy Policy does not require any physical, electronic or digital signature.</p>
            <p>This Privacy Policy is a legally binding document between you and Nethaat interactive solutions private limited. The terms of this Privacy Policy will be effective upon your acceptance of the same (directly or indirectly in electronic form, by clicking on the I accept tab or by use of the platform or by other means) and will govern the relationship between you and Nethaat interactive solutions private limited for your use of the website &ldquo;<strong>iPark platform</strong>&rdquo; (defined below).</p>
            <p>This document is published and shall be construed in accordance with the provisions of the Information Technology (reasonable security practices and procedures and sensitive personal data of information) rules, 2011 under Information Technology Act, 2000; that require publishing of the Privacy Policy for collection, use, storage and transfer of sensitive personal data or information.</p>
            <p>Please read this Privacy Policy carefully by using the iPark platform, you indicate that you understand, agree and consent to this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use this platform.&nbsp;</p>
            <p>By providing us your Information or by making use of the facilities provided by the iPark platform, You hereby consent to the collection, storage, processing and transfer of any or all of Your Personal Information and Non-Personal Information by us as specified under this Privacy Policy. You further agree that such collection, use, storage and transfer of Your Information shall not cause any loss or wrongful gain to you or any other person.</p>
            `
          },
          {
            title: "USER INFORMATION",
            description: `
            <p>To avail certain services on our Websites, users are required to provide certain information for the registration process namely: - a) your name, b) email address, c) credit card or debit card details d) mobile number. The Information as supplied by the users enables us to improve our sites and provide you the most user-friendly experience.</p>
            <p>All required information is service dependent and we may use the above said user information to, maintain, protect, and improve its services (including advertising services) and for developing new services</p>
            <p>Such information will not be considered as sensitive if it is freely available and accessible in the public domain or is furnished under the Right to Information Act, 2005 or any other law for the time being in force.</p>
            `
          },
          {
            title: "LINKS TO THE OTHER SITES",
            description: `<p>Our policy discloses the privacy practices for our own platform only. Our site provides links to other websites also that are beyond our control. We shall in no way be responsible in any way for your use of such sites.</p>`
          },
          {
            title: "INFORMATION SECURITY",
            description: `<p>We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data. These include internal reviews of our data collection, storage and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data.</p>
            <p>All information gathered on our platform is securely stored within our controlled database. The database is stored on servers secured behind a firewall; access to the servers is password-protected and is strictly limited. However, as effective as our security measures are, no security system is impenetrable. We cannot guarantee the security of our database, nor can we guarantee that information you supply will not be intercepted while being transmitted to us over the Internet.</p>
            <p>However the internet is an ever evolving medium. We may change our Privacy Policy from time to time to incorporate necessary future changes. Of course, our use of any information we gather will always be consistent with the policy under which the information was collected, regardless of what the new policy may be.</p>
            `
          },
          {
            title: "Grievance Redressal",
            description: `<p>Redressal Mechanism: Any complaints, abuse or concerns with regards to content and or comment or breach of these terms shall be immediately informed to the designated Grievance Officer as mentioned below via in writing or through email signed with the electronic signature to&nbsp;Yasir ali ("Grievance Officer").</p><br/>
            <p>Mr. Yasir Ali <strong>(Grievance Officer)</strong><br /><strong>www.nethaat.in</strong><br />Nethaat Interactive solutions private limited </p>
            <p>Email: yasir.ali@nethaat.in<br />Ph: +91 78992 55591</p>
            `
          }
          ]
        },
        {
          name: "Disclaimer",
          type: "disc",
          data: [{
            title: "Interpretation and Definitions",
            description: `<h5><strong>Interpretation</strong></h5>
            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.</p>
            <p>The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
            <h5><strong>Definitions</strong></h5>
            <p><strong>For the purposes of this Disclaimer:</strong></p>
            <ul style="list-style-type: circle;">
              <li><strong>Company</strong> (referred to as either "the Company", "We", "Us", &rdquo;iPark&rdquo;, "Our" or &ldquo;platform&rdquo; in this Policy) refers to NetHaat interactive solutions private limited.</li>
              <li><strong>You</strong> means the individual accessing the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
              <li><strong>Application</strong> means the software program provided by the Company downloaded by You on any electronic device named iPark.</li>
              <li><strong>Service</strong> refers to the Website or the Application or both.</li>
            </ul>`
          },
          {
            title: "Disclaimer",
            description: `<p>The information contained on the Service is for general information purposes only.</p>
            <p>The Company assumes no responsibility for errors or omissions in the contents of the Service.</p>
            <p>In no event shall the Company be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. The Company reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice. The Company does not warrant that the Service is free of viruses or other harmful components.</p>`
          },
          {
            title: "External Links Disclaimer",
            description: `<p>The Service may contain links to external websites that are not provided or maintained by or in any way affiliated with the Company.</p>
            <p>Please note that the Company does not guarantee the accuracy, relevance, timeliness, or completeness of any information on these external websites.</p>`
          },
          {
            title: "Errors and Omissions Disclaimer",
            description: `<p>The information given by the Service is for general guidance on matters of interest only. Even if the Company takes every precaution to insure that the content of the Service is both current and accurate, errors can occur. Plus, given the changing nature of laws, rules and regulations, there may be delays, omissions or inaccuracies in the information contained on the Service.</p>
            <p>The Company is not responsible for any errors or omissions, or for the results obtained from the use of this information.</p>`
          },
          {
            title: "Views Expressed Disclaimer",
            description: `<p>The Service may contain views and opinions which are those of the authors and do not necessarily reflect the official policy or position of any other author, agency, organization, employer or company, including the Company.</p>
            <p>Comments published by users are their sole responsibility and the users will take full responsibility, liability and blame for any libel or litigation that results from something written in or as a direct result of something written in a comment. The Company is not liable for any comment published by users and reserve the right to delete any comment for any reason whatsoever.</p>`
          },
          {
            title: "No Responsibility Disclaimer",
            description: `<p>The information on the Service is provided with the understanding that the Company is not herein engaged in rendering legal, accounting, tax, or other professional advice and services. As such, it should not be used as a substitute for consultation with professional accounting, tax, legal or other competent advisers.</p>
            <p>In no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever arising out of or in connection with your access or use or inability to access or use the Service.</p>`
          },
          {
            title: `"Use at Your Own Risk" Disclaimer`,
            description: `<p>All information in the Service is provided "as is", with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability and fitness for a particular purpose.</p>
            <p>The Company will not be liable to You or anyone else for any decision made or action taken in reliance on the information given by the Service or for any consequential, special or similar damages, even if advised of the possibility of such damages.</p>
            <h5><strong>Contact Us</strong></h5>
            <p>If you have any questions about this Disclaimer, You can contact Us:</p>
            <ul style="list-style-type: circle;">
            <li>By email: <strong>sarwesh@nethaat.in</strong></li>
            <li>By phone number: <strong>+91 99611 703068</strong></li>
            </ul>`
          }
          ]
        }
      ]
    },
    isee: {
      title: "iSee",
      downloadIos:
        "https://install.appcenter.ms/orgs/Nethaat/apps/Breeze-Security-APP/releases/8",
      downloadAndroid:
        "https://install.appcenter.ms/orgs/Nethaat/apps/Breeze-Security-APP/releases/8",
      cards: [
        {
          name: "Report Incident",
          description:
            "Alert your native law enforcement, Report an incident the moment you see it happening right from your smartphone.",
          logo: "/img/logos/report.png"
        },
        {
          name: "Pay your fine",
          description:
            "Clear out on all your monetary penalties effortlessly through online payment, View and pay all your fines simply from your smartphone",
          logo: "/img/logos/fine.png"
        },
        {
          name: "Book pass",
          description:
            "Book traffic passes to avoid the exasperating annoyance of getting pulled over by an officer.",
          logo: "/img/logos/booking.png"
        }
      ],
      description: [
        "You are awesome! Because you chose to make a difference in society, now not everything will go unnoticed. You can now report through your smartphone, an incident immediately when you see it happening.",
        "Take a picture of the incident and report it, the incident will be designated to the law enforcement officer-in-charge, Also, get real-time updates from the officer.",
        "Psst… You can report an incident anonymously as well. !",
        "Monetary penalties are sometimes unavoidable, along comes the unpleasing task of clearing them, with iSee view and pay all your pending challans from the free iSee app via online payment mode.",
        "Buy a pass simply from your device to avoid getting pulled over by an officer, hit the pass section to see the passes available in your city.",
        "Bringing a change in the society not so hard, right? Get the free iSee app today."
      ],
      info: [
        {
          name: "User Guide",
          data: [
            {
              title: "How to report an incident anonymously?",
              description: `First things first! Reporting an incident can be done anonymously also, obviously, there is no
                    harm in being a registered user though.
                    Step1: Select the option ‘Continue without Log-in’ on the login page.
                    Step2: Click pictures of the incident add a caption to it and click on the send button.
                    Note: You can click multiple pictures for reporting the incident.
                    When you report an incident, Date, time, Pin code (of the area where the incident occurred), is
                    automatically recorded by the app. The officer-in-charge will be notified about the incident
                    along with these details. All the incidents reported by you are listed as ‘Your reported incidents’
                    on the home page.`
            },
            {
              title: "How to report an Incident?",
              description: `Reporting an incident as a registered user is as easy as you think, just follow the simple steps listed below – 
                    Step1: Enter your mobile number on the log-in page. 
                    We will send you a One Time Password (OTP) to verify it’s you, we don’t want somebody to misuse your number either. 
                    Step2: Enter the OTP you received via SMS.
                    You are now a registered iSee user. 
                    Note: You don’t have to enter your mobile number and OTP every time you report an incident.
                    Step3: Click on the camera icon on the bottom right corner of the screen on the home page, this opens the camera to click pictures of the incident you want to report.
                    Step4: Click single or multiple pictures of the incident, add a description to the picture explaining (not completely though but a summary will do) the scenario to the officer, a little heads up would definitely help police officers take any action quickly.
                    Step5: Upload the pictures by clicking on the ‘send’ button. 
                    The incident is sent successfully along with the date and time, GPS location and Pin code to the officer-in-charge of the area in which the incident occurred. 
                    
                    You can check the status of the incident, get updates once the issue is resolved it is updated as ‘Resolved’ in the ‘Your Reported Incidents’ list.
                    P.S….. 
                    Let’s say you have reported a rash driving cab driver, you can see the list of people who also have reported the same driver/vehicle. `
            },
            {
              title: "How to upload documents?",
              description: `Your mobile number in the INCIDENT app is registered at the time of log-in, you can choose to upload your documents whenever you get the time to. 
                    You need to upload two types of documents – (i) Personal documents (ii) Vehicle documents
                    Personal documents – You shall upload a picture of your Adhaar card and a picture of your Passport.
                    Vehicle documents – You shall upload a picture of the Registration Certificate (RC) and vehicle number (this will help INCIDENT app to determine any challans issued on your vehicle).`
            },
            {
              title: "How to pay a challan?",
              description: `INCIDENT app helps you check and pay any challans issued on your vehicle, 
                    Step1: Click on the ‘Challans’ button in the bottom of the screen on the home page.
                    Step2: Enter/select the Vehicle Number in the empty space provided.
                    All the challans issued (if any) on that particular vehicle is now visible to you in the form of a list, 
                    Step3: click on the ‘Pay’ button present on the challan you want to pay.
                    Step4: Select a mode of payment 
                    Hurray! You have successfully paid your challan. 
                    `
            },
            {
              title: "What is a pass? And How to buy a pass?",
              description: `Need to take your vehicle out for something important? But can’t, why? 
                    Because of the odd/even rationing rule. Do we have a solution? Of course, we do! 
                    Introducing odd/even pass for driving around your even-numbered vehicle on an odd number rationing day. 
                    Passes are created and updated in the app by the authorities; you can see the list of available passes, buy the pass which best suits your requirement. 
                    Click on the ‘Pass’ option present in the bottom right corner next to ‘Challans’, you will see a list of passes available for you to buy. Buy a pass according to your requirements. 
                    You are now good to go! Flash this pass when you are pulled over by an officer on the street/road.`
            }
          ]
        },
        {
          name: "Frequently Asked Questions",
          data: [
            {
              title: "Log-in and registration",
              description: `What does it mean by “continue without log-in”? `
            },
            {
              title: "Documents: ",
              description: `I don’t have a passport; can I upload any other document instead of a passport?
                        Why should I share my personal details or upload my personal documents?
                        Can I upload multiple vehicle documents?
                        What are the vehicle documents I need to upload?
                        `
            },
            {
              title: "Incident",
              description: `Can I upload a picture of an incident saved on my mobile phone?
            How do I know the status of the reported incident?
            What can be considered a reportable incident?
            Do I have to enter the location where the incident happened?
            Who views my reported incident?`
            }
          ]
        },
        {
          name: "For Traffic Authorities",
          data: [
            {
              title: "Link",
              description: `https://pg-app-w3alycxwa8l9hecge94rel8eywt52x.scalabl.cloud/`
            }
          ]
        }
      ],
      downloadIos: "",
      downloadAndroid: ""
    },
    gotog: {
      title: "Go2Getha",
      downloadIos:
        "https://install.appcenter.ms/orgs/Nethaat/apps/Breeze-Security-APP/releases/8",
      downloadAndroid:
        "https://install.appcenter.ms/orgs/Nethaat/apps/Breeze-Security-APP/releases/8",
      cards: [
        {
          name: "Sign Up",
          description:
            "Seamlessly sign up with a few clicks and a little piece of information.",
          logo: "/img/logos/signup.png"
        },
        {
          name: "Post a Ride",
          description:
            "Riding alone? Share your ride by posting it. After all, sharing is caring!",
          logo: "/img/logos/postride.png"
        },
        {
          name: "Chat and Select",
          description:
            "Go2Getha local social networking site – Find a match connect with the people travelling in the same route over a chat.",
          logo: "/img/logos/chatandselect.png"
        },
        {
          name: "Ride Share",
          description:
            "Feel like a match? Catch-up with your co-rider at a pre-determined pick-up spot, go on and share your ride.",
          logo: "/img/logos/rideshare.png"
        }
      ],
      description: [
        "Go2getha is a free service launched with the aim of easing the woes of everyday commuting. We at Go2getha are a bunch of seasoned professionals with experience in business, sales, technology, and marketing. Through our combined endeavor we aim to bring about a change in the way people view the commuting experience.",
        "It all started off when we saw the daily commuting struggles of office-goers in our home city of Mumbai. The over-flowing trains & buses, choked roads, moody cabs and expensive app-based options combined with the sweltering heat actually increased stress and reduced productivity.",
        "We thought we would use the power of technology and smart-phones to make traveling an all-inclusive affair beyond just the cabs and rickshaws. Even vehicle owners could benefit from our platform. And thus, Go2getha was born.",
        "Moreover, apart from home to work commutes, there was a huge segment of commutes that happen for sales calls, business development visits, casual rides, station to home travel, etc. which were very poorly served by the existing options available. This is where Go2getha comes in."
      ],
      info: [
        {
          name: "About the Service",
          data: [
            {
              title: "What is Go2Getha?",
              description: `Go2Getha is a peer-to-peer people connect platform that helps you find & share rides with other users on the same route as you. You can provide, seek and share rides with others anytime, anywhere. You don’t need to plan your travels, book expensive cabs, or fund your online wallets. Just enter your route, find a companion, hop-on and hop-off.
                        Moreover, it is cost-effective, environment friendly and an efficient way to commute.`
            },
            {
              title: "How does it work?",
              description: `Go2Getha works through a website as well as App. Personally, we recommend using the App which gives you the benefit of GPS to find fellow riders.
                        Once you have signed up, simply open the App (or login on the website) & enter your travel plans. Go2Getha will display the suitable ride matches in a friendly map and chat view. Choose your best match, chat with them and share the ride.`
            },
            {
              title: "Who can use Go2Getha?",
              description: `Anyone with a legitimate email address and mobile number can join Go2Getha. Those willing to share their vehicle must additionally have a valid driving license and the vehicle documents. However, Go2Getha users need to be at least 18 years of age to use the service.`
            },
            {
              title: "What can I do with Go2Getha?",
              description: `Go2Getha works best for your unplanned or planned daily commutes. It saves you from the over-crowded public transport, fare refusals by moody cabbies, overpriced taxies and dreadful surge-pricing (by splitting costs).
                        There is always someone who is riding at the same time and in the same direction as you are. Go2Getha helps you find that person in an easy, on the go manner. And yes, it needn’t be just a car pool – it could be a cab, rickshaw and even a bike pool, as long as there is space available.
                        The interesting part is that it works in two ways. You too could use your ‘shared-space’ to offer rides to fellow commuters. It kills the boredom and also helps cut your commute costs significantly.
                        Moreover, you can also use Go2Getha for your inter-city rides with work colleagues and other Go2Getha members on your route.`
            },
            {
              title: "Is Go2Getha restricted to specific cities?",
              description: `No. Go2Getha can be used to commute or travel from anywhere to anywhere as long as someone else is on the same route and time as you. The beauty of our people connect platform is that it matches riders on basis routes independent of whether it is intra-city or inter-city in any country across the world. However, the services are presently being offered in India only.`
            },
            {
              title: "What modes of transport can I use to share my ride?",
              description: `You can share a ride with your companion in your/his private vehicle (can be a two wheeler or four wheeler), or share a public transport like taxis, cabs or rickshaws.`
            }
          ]
        },
        {
          name: "Getting Started",
          data: [
            {
              title: "Where can I get the Go2Getha App?",
              description: `Go2Getha is available on iOS (App Store) as well as Android (Play Store).`
            },
            {
              title: "How do I Sign Up?",
              description: `Signing Up is easy. Enter your full name, email address & mobile number. Confirm your mobile number by entering the OTP. This gives you an instant access to the app. For web signups, the login password is in your email. Please do check your SPAM folder if you did not receive the email. Go2Getha is currently available only for Indian citizens using the service within the geographical boundaries of India. However, we will soon be available in other countries globally.`
            },
            {
              title: "How do I Sign In?",
              description: `Once you’ve signed up with Go2Getha, you can sign-in by using either your mobile number or your email address along with your password. If you are signing in for the first time, the password is in your inbox. Again, please do check your SPAM folder if you did not receive the email. Do remember to change the password after the first sign in.`
            },
            {
              title: "How do I find other rider travelling on similar route?",
              description: `When a user posts his travel plan, either as a ride seeker or as a ride provider, the plan is recorded in our ride database. Now, when you post your travel plan, the system will record your ride and present you with other similar ride postings. You can choose the most suitable match and request them to share a ride with you. In case do don’t find a match, then other user who might be interested in sharing the ride with you would send you a request. Simply acknowledge the request and you are good to go.
                        Our unique chat application also allows you to co-ordinate with your fellow co-rider till you reach your destination/complete the ride.`
            },
            {
              title: "How can I retrieve my lost password?",
              description: `You can opt to retrieve your password through email as well as mobile. However, the password can be retrieved on your mobile only if your mobile number is verified by Go2Getha.`
            }
          ]
        },
        {
          name: "Riding with Go2Getha",
          data: [
            {
              title: "How do I start a ride on Go2Getha?",
              description: `All you need to do is click on ‘Share Ride’, choose the role you are playing (Seeker or Provider), select your from and to locations. You will then be prompted to select the date and time of your ride and the number of people you want to ride with you.
                        You can also include additional filters like distance ‘from’ and ‘to’ basis your location, the start time difference, minimum verification and rating levels. Lastly, you need to choose the type of vehicle and the maximum number of riders for that ride.`
            },
            {
              title: "What happens next?",
              description: `Your request is published by Go2Getha and all relevant matches basis your parameters are shown to you. You can choose to broadcast a message to the matches and then select the most appropriate one for you to ride with. You can then chat with your potential co-rider in the App, decide on the details of the ride and accept the ride.`
            },
            {
              title: "What do I do after accepting a ride?",
              description: `Post accepting, you can coordinate the specifics of the ride with your co-riders using the chat. We recommend that you finalise details like exact pick-up location & time, landmarks, how to identify each other, splitting of fare/cost etc. on the chat before your ride starts. Please note that the 24-hour period for your complimentary insurance cover would start as soon as you accept the ride on our system irrespective of when the actual ride starts.`
            },
            {
              title: "Can I cancel a ride once accepted?",
              description: `Yes. You can abort a ride before it commences but we recommend you avoid using this unless absolutely necessary as it not only affects fellow riders but would also adversely impact your rating.`
            },
            {
              title: "Can I add more users to the chat once I accept the ride?",
              description: `No. Once the ride is accepted basis the parameters given by you, you cannot add more users to the chat. The only option for you would be to publish a fresh ride request.`
            }
          ]
        },
        {
          name: "Fees & Charges",
          data: [
            {
              title: "How much do I pay Go2Getha?",
              description: `Nothing. That’s true, you pay absolutely nothing to Go2Getha. Free download and sign up, free posting, free ride matching. No hidden charges.`
            },
            {
              title: "So there are no financial transactions in Go2Getha?",
              description: `There is. The only time you shell out from your pocket is when your ride is completed and you pay the co-rider (in case you are a seeker) as per the amount agreed upon by both the parties at the start of the ride. In case you are sharing a public transport, you simply split the total fare with your co-riders.
                        Go2Getha is not involved in this process. We are happy just finding you a fellow rider.`
            },
            {
              title:
                "I understand Go2Getha provides an insurance? Do we have to pay for it?",
              description: `Go2Getha has your ride insured for any potential mishap, for FREE. Yes, you heard it right. This accidental insurance cover is extended to the users with three Trust Circles and is valid for 24 hours from the time you join the ride as per system.
                        The specifics of the insurance cover are as under:
                        The Cover is a Group Personal Accident Policy (GPA) covering Death & Personal Accident Policy
                        Is available for 24 hours after the start of any ride as per Indian Standard Time (IST)
                        User has to be above 18 years of age
                        Should have completed their 3 levels of verification
                        Have completely updated their ‘Profile’ in the relevant section with details like ‘Date of Birth’, ‘Nominee name’, ‘Relationship’ etc.
                        Cover is currently limited to users in India
                        Multiple rides do mean multiple insurance cover. At any given point of time, a user can be eligible for only one insurance cover
                        Two individuals matching rides with each other would not be eligible for cover beyond a maximum of 3 rides a month
                        All policies of the Insurance Co need to be satisfied and all claims would be directly settled by the Insurer
                        The process for claiming the in`
            }
          ]
        },
        {
          name: "Safety",
          data: [
            {
              title: "How is it safe?",
              description: `Only users with registered public profiles and phone number are allowed to sign-up on Go2Getha. We verify physical address and/or back account details of every user with two or more Trust Circles, and in case of any mishap, we can physically trace them. Additionally, we monitor user ratings to disallow members on the platform.
                        Still intimidating? Consider the fact that your current local cab, rickshaw ride doesn’t assure you on any of these parameters.`
            },
            {
              title: "Can users see my contact details?",
              description: `No. Except your name, all your contact details remain hidden. Go2Getha does not share any of your personal information with other users. You can still communicate seamlessly with your co-rider through our integrated chat platform.`
            },
            {
              title: "Is Go2Getha safe for women?",
              description: `Go2Getha is extremely particular about the safety of our female users. Apart from various screening & security measures, Go2Getha has a preferred female option wherein a female member can make a preference to only ride with another female member.`
            }
          ]
        },
        {
          name: "Social",
          data: [
            {
              title: "Why should I rate my fellow riders?",
              description: `Go2Getha encourages users to rate each other after their ride. This increases trust in our community. Average ratings are visible to all Go2Getha users to enable them to choose their company accordingly.
                        We request that you rate fellow Go2Getha users on the basis of experience and not personal opinion.`
            },
            {
              title: "Can I make friends on Go2Getha?",
              description: `Most definitely! You can view profiles of other riders on similar travel route. We’re taking the boring out of commuting! Trusted co-commuters make for a happy ride.`
            },
            {
              title: "How do I contact fellow Go2Getha members?",
              description: `Go2Getha offers an in-app chat feature to have extended conversations. Though, the messenger is automatically deactivated after the completion/cancellation of the ride. If you want to stay in touch even after the ride, exchange your numbers.`
            },
            {
              title:
                "How do I make the most of the chat feature before starting a ride?",
              description: `Our unique chat application is a great platform for you to decide on a potential co-rider and discusses all the necessary details before starting the ride. We suggest you use the chat to finalise the pick-up location, time, any landmarks, how to identify each other, which route to take, how to split the cost etc. upfront. This will help make your ride easier and would help save time.
                        A sample of a typical broadcast & chat message is included below:`
            }
          ]
        }
      ],
      downloadIos: "",
      downloadAndroid: ""
    }
  };
}
