import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import details from "./details";
import LoadingScreen from './LoadingScreen';
const Food = lazy(() => import('./Food'));
const About = lazy(() => import('./About'));

const FoundersAndManagement = lazy(() => import('./FoundersAndManagement'));
const Traffic = lazy(() => import('./Traffic'));
const Ride = lazy(() => import('./Ride'));
const Parking = lazy(() => import('./Parking'));
const Home = lazy(() => import('./Home'));
const ContactUs = lazy(() => import('./ContactUs'));
const Product = lazy(() => import('./Product'));


export default class App extends React.Component {

  constructor(props) {
    super(props);
  }

  
  render() {

    return (
      <Router>
        <Suspense fallback={<LoadingScreen />}>
          <Switch>
            <Route exact path="/contact">
              <ContactUs />
            </Route>
            <Route exact path="/about-us">
              <About />
            </Route>
            <Route exact path="/food">
              <Food />
            </Route>
            <Route exact path="/founders-and-management">
              <FoundersAndManagement />
            </Route>
            <Route exact path="/traffic">
              <Traffic />
            </Route>
            <Route exact path="/ride">
              <Ride />
            </Route>
            <Route exact path="/parking">
              <Parking />
            </Route>
            <Route exact path="/product">
              <Product data={details().ipark} />
            </Route>
            <Route path="/product/ipark">
              <Product data={details().ipark} />
            </Route>
            <Route path="/product/bulkget">
              <Product data={details().bulkget} />
            </Route>
            <Route path="/product/isee">
              <Product data={details().isee} />
            </Route>
            <Route path="/product/go2getha">
              <Product data={details().gotog} />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    );
  }
}
